<template>
  <div>
    <span style="font-size:20px;">{{ this.$store.state.scoring.selectedPerson.firstname }} {{ this.$store.state.scoring.selectedPerson.lastname }}</span> at <span style="font-size:20px">{{ this.$store.state.scoring.selectedDealer.dealername }}</span>
    <br>
    <b><span style="color:#cc0000;">red=no</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#008800;">green=yes</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#000000;">black=na</span></b>
    <br><br>
    Include operator scoring?
    <el-select v-model="operatorScoringSelection" placeholder="Select">
      <el-option label="No" value="N"></el-option>
      <el-option label="Yes" value="Y"></el-option>
    </el-select>
  </div>
</template>

<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form } from 'element-ui'

export default {
  name: 'Intro',
  components: {
    // intro,
    // 'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    // // 'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      dealerName: '',
      operatorScoring: '',
    };
  },
  computed: {
    operatorScoringSelection: {
      get() {
        return this.$store.state.scoring.operatorScoring;
      },
      set(value) {
        this.$store.commit('scoring/updateOperatorScoring', value);
      },
    },
  },
  mounted() {

  }
};
</script>
