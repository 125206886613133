import { authenticationService } from '@/_services';
import store from '@/store/store.js'; // Update the path to your actual store file

export function handleResponse(response) {
    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json")) {
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    authenticationService.logout();
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            store.dispatch('auth/setUserData', data.user);
            return data;
        });
    } else {
        // Handle non-JSON responses
        return response.text().then(text => {
            if (!response.ok) {
                // You can add more specific error handling here if needed
                return Promise.reject('Error: ' + response.status + ' - ' + text);
            }
            return text;
        });
    }
}
