<template>
  <card>
    <form @submit.prevent="handleSubmit">
      <el-select v-model="localDealers" placeholder="Select Dealer" class="mb-4" @change="handleDealerChange">
        <el-option label="Select Dealer" value="0"></el-option>
        <el-option v-for="dealer in dealersList" :key="dealer.dealerid" :label="dealer.dealername" :value="dealer.dealerid"></el-option>
      </el-select>

      <el-select v-model="localPeople" placeholder="Select Person" class="mb-4">
        <el-option v-for="person in peopleList" :key="person.personid" :label="person.firstname + ' ' + person.lastname" :value="person.personid"></el-option>
      </el-select>

      <el-select v-model="localCalltype" placeholder="Select Call Type" class="mb-4">
        <el-option label="Inbound" value="inbound"></el-option>
        <el-option label="Outbound" value="outbound"></el-option>
        <el-option label="Service" value="service"></el-option>
        <el-option label="1 on 1" value="1on1"></el-option>
        <el-option label="Mystery Shop" value="mystery"></el-option>
      </el-select>

      <el-date-picker v-model="localCoachdate" type="date" placeholder="Date to coach this call" class="mb-4"></el-date-picker>

      <textarea v-model="localComment" id="comment" rows="1" cols="50" placeholder="Enter comment" class="el-textarea__inner"></textarea>

      <el-button class="mt-2" type="success" @click="handleSubmit">Submit</el-button>
    </form>
  </card>
</template>

<script>
import { DatePicker, Select, Option, Button, Card } from 'element-ui';

export default {
  name: "CoachAssistantForm",
  components: {
    'el-date-picker': DatePicker,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    'card': Card,
  },
  props: {
  dealersList: Array,
  peopleList: Array,
  dealers: [String, Number],
  people: [String, Number],
  calltype: String,
  coachdate: [String, Date],  // Allow both String and Date
  comment: String,
},
  data() {
    return {
      localDealers: this.dealers,
      localPeople: this.people,
      localCalltype: this.calltype,
      localCoachdate: this.coachdate,
      localComment: this.comment,
    };
  },
  methods: {
     handleDealerChange(value) {
    this.$emit('update:dealers', value); // Emit the dealer change to the parent
  },
    handleSubmit() {
      this.$emit("updateForm", {
        dealers: this.localDealers,
        people: this.localPeople,
        calltype: this.localCalltype,
        coachdate: this.localCoachdate,
        comment: this.localComment,
      });
      this.$emit("submitForm", {
        dealers: this.localDealers,
        people: this.localPeople,
        calltype: this.localCalltype,
        coachdate: this.localCoachdate,
        comment: this.localComment,
      });
    },
  },
};
</script>
