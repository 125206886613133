<template>
  <div class="file-upload">
    <input type="file" ref="file" @change="handleFileUpload()" accept=".mp3,.wav" hidden>
    <el-button variant="primary" @click="triggerFileInput">Upload MP3 or WAV</el-button>
  </div>
</template>

<script>
import { Button } from 'element-ui';

export default {
  components: {
    'el-button': Button,
  },
  methods: {
    triggerFileInput() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      const files = this.$refs.file.files;
      if (files.length > 0) {
        // Here you can handle the file, display it, or send it to the server
        this.$store.commit('inbound/updateAudioFile', files[0]);
         this.$emit('file-selected', files[0]); 
      }
    }
  }
}
</script>

<style scoped>

</style>
