<template>
  <div>
    <div class="alert alert-info">
      <strong>Normal User</strong> - U: user P: user<br />
      <strong>Administrator</strong> - U: admin P: admin
    </div>
    <h2>Login</h2>
    <form @submit.prevent="onSubmit()">
      <div class="form-group">
        <label for="email">email</label>
        <input
          type="text"
          v-model.trim="$v.email.$model"
          name="email"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.email.$error }"
        />
        <div v-if="submitted && !$v.email.required" class="invalid-feedback">
          email is required
        </div>
      </div>
      <div class="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          v-model.trim="$v.password.$model"
          name="password"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.password.$error }"
        />
        <div v-if="submitted && !$v.password.required" class="invalid-feedback">
          Password is required
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary" :disabled="loading">
          <span
            class="spinner-border spinner-border-sm"
            v-show="loading"
          ></span>
          <span>Login</span>
        </button>
      </div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
    </form>
     <!-- Success or Error Alert -->
    <l-alert v-if="alert.success" type="success" dismissible="">
      <span><b>Success - </b>{{ alert.message }}</span>
    </l-alert>
    <l-alert v-if="alert.error" type="danger" dismissible="">
      <span><b>Error - </b>{{ alert.message }}</span>
    </l-alert>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { router } from "@/_helpers";
import { authenticationService } from "@/_services";
//import { configureFakeBackend } from "@/_helpers/fake-backend";
import { authenticateUser } from "@/_helpers/authenticateUser";
import LAlert from 'src/components/Alert'; // Import the l-alert component
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';

export default {
   components: {
    LAlert, // Register l-alert component
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      returnUrl: "",
      error: "",
      alert: {
        success: false,
        error: false,
        message: '',
      },
    };
  },
  validations: {
    email: { required },
    password: { required },
  },
  created() {
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      return router.push("/");
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  methods: {
   async onSubmit() {
    this.submitted = true;

    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    this.loading = true;

    try {
      const response = await authenticateUser(this.email, this.password);
      console.log(bla)
      console.log(response);
      // If the login is successful, show a success notification
      this.$notify({
        component: NotificationSample,
        icon: 'nc-icon nc-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'success',
        message: response.message // assuming response contains the success message
      });

      // Redirect the user
      router.push(this.returnUrl);
      
    } catch (error) {
      console.log(error);
      // Check if the error has a response object
      if (error.response) {
        // Handle 401 Unauthorized error
        if (error.response.status === 401) {
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: response.error || 'Authentication failed: Unauthorized'
          });
        } else {
          // Handle other possible errors
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: `Error: ${error.response.status} - ${error.response.data.error || 'An error occurred'}`
          });
        }
      } else {
        // If no response object, show a generic error
        this.$notify({
          component: NotificationSample,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
          message: 'An unexpected error occurred'
        });
      }
    } finally {
      this.loading = false;
    }
  }
},
};
</script>
