<template>
  <card>
    <div class="p-4"><b>Calls I Coached:</b></div>

    <el-input v-model="localSearchQuery" class="mb-3" placeholder="Search coached sessions" suffix-icon="el-icon-search" @input="search">
    </el-input>

    <el-table :data="sessions" style="width: 100%;" border>
      <el-table-column label="View">
        <template slot-scope="scope">
          <el-button @click.prevent="$emit('view-click', scope.row)">View</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="scheduledcoachdate" label="Date">
        <template slot-scope="scope">{{ formatDate(scope.row.scoringtimestamp) }}</template>
      </el-table-column>
      <el-table-column prop="calltype" label="Call Type"></el-table-column>
      <el-table-column prop="dealername" label="Dealer"></el-table-column>
      <el-table-column label="Person">
        <template slot-scope="scope">{{ scope.row.firstname + ' ' + scope.row.lastname}}</template>
      </el-table-column>s
      <el-table-column prop="coachname" label="Coach"></el-table-column>
      <el-table-column prop="totalscore" label="Score"></el-table-column>
      <el-table-column prop="reviewed" label="Reviewed"></el-table-column>
    </el-table>

    <l-pagination
      :current-page="localPagination.currentPage"
      :page-size="localPagination.perPage"
      layout="total, prev, pager, next"
      :total="localPagination.total"
      @current-change="onPageChange"
    >
    </l-pagination>
  </card>
</template>

<script>
import { Table, Button, TableColumn, Pagination, Input, Card } from "element-ui";

export default {
  name: "CoachedSessionsTable",
  components: {
    "el-button": Button,
    "el-table": Table,
    "el-table-column": TableColumn,
    "l-pagination": Pagination,
    card: Card,
    "el-input": Input,
  },
  props: {
    sessions: Array,
    pagination: Object,
    searchQuery: String,
  },
  data() {
    return {
      localSearchQuery: this.searchQuery, // Create a local copy of searchQuery
      localPagination: { ...this.pagination }, // Create a local copy of pagination
    };
  },
  methods: {
    search() {
      this.$emit("search", this.localSearchQuery); // Emit event to parent to update searchQuery
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onPageChange(newPage) {
      this.localPagination.currentPage = newPage;
      this.$emit("page-change", newPage); // Emit event to parent to update pagination
    },
  },
  watch: {
    // Watch for changes in the props and update local data accordingly
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    },
    pagination: {
      handler(newVal) {
        this.localPagination = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
