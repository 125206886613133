<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-auto w-100">
        <el-card v-if="!showRatingSection">
          <h3>Start new inbound call:</h3>

          <el-form @submit.prevent="submitNewCall">
            <el-select class="pt-2" v-model="selectedDealer" placeholder="Select Dealer" @change="fetchPeople">
              <el-option v-for="dealer in dealersList" :key="dealer.dealerid" :label="dealer.dealername"
                :value="dealer.dealerid"></el-option>
            </el-select>

            <el-select class="pt-2" v-model="selectedPerson" placeholder="Select Person">
              <el-option v-for="person in peopleList" :key="person.personid"
                :label="person.firstname + ' ' + person.lastname" :value="person.personid"></el-option>
            </el-select>
            <div class="pt-4">
            <el-button  type="primary" @click="submitNewCall">Next</el-button>
            </div>
          </el-form>
        </el-card>
        <el-card v-if="showRatingSection">
          <h3>SCORING:</h3>
          <l-button @click="resetSelectedCall" type="default" wide outline>
            <span class="btn-label">
              <i class="fa fa-arrow-left"></i>
            </span>
            Back
          </l-button>
          <!-- FORM START-->
          <intro></intro>
          <ScoreSheetCoachAssistantInbound></ScoreSheetCoachAssistantInbound>
          <!-- FORM END-->
          <!-- <el-form @submit.prevent="submitNewCall">
           

            <el-button type="primary" @click="submitNewCall">Next</el-button>
          </el-form> -->
        </el-card>
        <el-card>
          <el-table :data="pagedInboundCalls" style="width: 100%" border>
            <el-table-column label="Date" min-width="120">
              <template slot-scope="scope">
                {{ formatDate(scope.row.scoringtimestamp) }}
              </template>
            </el-table-column> <el-table-column prop="dealername" label="Dealer" width="200"></el-table-column>
            <el-table-column label="Person">
              <template slot-scope="scope">
                {{ scope.row.firstname }} {{ scope.row.lastname }}
              </template>
            </el-table-column>
            <el-table-column prop="coachname" label="Coach" width="200"></el-table-column>
            <el-table-column prop="totalscore" label="Score" width="120"></el-table-column>
            <el-table-column prop="reviewed" label="Reviewed" width="120"></el-table-column>
            <el-table-column fixed="right" label="Actions" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="editCall(scope.row)">Edit</el-button>
              </template>
            </el-table-column>
            <!-- Add more columns as needed -->
          </el-table>
          <el-pagination @current-change="handlePageChange" :current-page="pagination.currentPage"
            :page-size="pagination.perPage" layout="total, prev, pager, next" :total="pagination.total">
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>

import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form } from 'element-ui'
import { mapState } from "vuex";
import intro from '../../../components/intro.vue';
import ScoreSheetCoachAssistantInbound from '../../../components/ScoreSheetCoachAssistantInbound.vue';
export default {
  components: {
    intro,
    ScoreSheetCoachAssistantInbound,
    'el-card': Card,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    // 'el-input': Input,
    'el-button': Button,
    'el-form': Form,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      dealersList: [],
      peopleList: [],
      inboundCalls: [],
      searchQuery: '',
      selectedDealer: null,
      selectedPerson: null,
      showRatingSection: false,
      sessions: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0
      },
      fuse: null
    };
  },
  computed: {
    ...mapState('session', ['total']),
    //math

    pagedInboundCalls() {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.inboundCalls.slice(start, end);
    },
    queriedData() {
      if (!this.searchQuery) return this.paginate(this.sessions);
      else return this.paginate(this.sessionsSearch);
    }
  },
  watch: {
    // Watch the dealers variable for changes

  },
  methods: {
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    editCall(row) {
      // Navigate to the scoring sheet with the scoring ID
      this.$router.push({ name: 'ScoringSheetInbound', params: { id: row.scoringid } });
    },
    fetchDealers() {
      // API call to fetch dealers and populate this.dealers
      fetch('/api/dealers')
        .then(response => response.json())
        .then(data => this.dealersList = data)
        .catch(error => console.error('Error fetching dealers:', error));
    },
    fetchPeople(dealerId) {
      const dealer = this.dealersList.find(dealer => dealer.dealerid === dealerId);
      if (dealer) {
        this.$store.commit('scoring/updateSelectedDealer', dealer);
      }
      // API call to fetch people based on the dealerId and populate this.people
      fetch(`/api/people?dealerid=${dealerId}`)
        .then(response => response.json())
        .then(data => this.peopleList = data)
        .catch(error => console.error('Error fetching people:', error));
    },
    fetchInboundCalls() {
      // API call to fetch inbound calls and populate this.inboundCalls
      fetch(`/api/inbound-calls`)
        .then(response => response.json())
        .then(data => {
          this.inboundCalls = data;
          this.pagination.total = data.length;
        })
        .catch(error => console.error('Error fetching inbound calls:', error));
    },
    generateNewScoringId() {
      if (this.inboundCalls.length > 0) {
        const maxScoringId = Math.max(...this.inboundCalls.map(call => call.scoringid));
        this.$store.commit('scoring/updateNewScoringId', maxScoringId + 1);
        return maxScoringId + 1;
      } else {
        return 1; // Default ID if no calls exist
      }
    },
    submitNewCall() {
      const person = this.peopleList.find(person => person.personid === this.selectedPerson);
      if (person) {
        this.$store.commit('scoring/updateSelectedPerson', person);
      }
      if (this.selectedDealer && this.selectedPerson) {
        const newScoringId = this.generateNewScoringId();
        this.$store.commit('scoring/updateNewScoringId', newScoringId);
        this.$router.push({ name: 'ScoringSheetInbound', params: { id: newScoringId } });


        // Proceed to show the rating section
        this.showRatingSection = true;
      } else {
        // Handle the case where one or both selections are missing
        alert("Please select both a dealer and a person."); // Or use a more user-friendly notification method
      }
    },
    resetSelectedCall() {
      this.showRatingSection = !this.showRatingSection;
    },
  },
  watch: {
    selectedDealer(newVal) {
      const dealer = this.dealersList.find(dealer => dealer.dealerid === newVal);
      if (dealer) {
        this.$store.commit('scoring/updateSelectedDealer', dealer);
      }
    },
    selectedPerson(newVal) {
      const person = this.peopleList.find(person => person.personid === newVal);
      if (person) {
        this.$store.commit('scoring/updateSelectedPerson', person);
      }
    }
  },
  created() {

  },
  mounted() {
    this.fetchDealers();
    this.fetchInboundCalls();
  },
};
</script>

<style></style>
