<template>
  <div>
    <h1>Home</h1>
    <p>
      Welcome {{ this.$store.state.auth.userData.firstname }}
      {{ this.$store.state.auth.userData.lastname }}
    </p>
    <p>
      Your role is: <strong>{{ this.$store.state.auth.userData.role }}</strong
      >.
    </p>
    <p>
      This page can be accessed by only
      {{ this.$store.state.auth.userData.role }}.
    </p>
    <div>
      <form
        v-if="(this.$store.state.auth.userData.role = 'coach assistant')"
        @submit.prevent="submitForm"
      >
        <!-- Dealers Dropdown -->
        <select v-model="dealers" @change="resetSelect">
          <option value="0">Select Dealer</option>
          <option
            v-for="dealer in dealersList"
            :key="dealer.dealerid"
            :value="dealer.dealerid"
          >
            {{ dealer.dealername }}
          </option>
        </select>

        <!-- People Dropdown -->
        <select v-model="people">
          <option
            v-for="person in peopleList"
            :key="person.personid"
            :value="person.personid"
          >
            {{ person.firstname }} {{ person.lastname }}
          </option>
        </select>

        <!-- Call Type Dropdown -->
        <select v-model="calltype">
          <option value="inbound">Inbound</option>
          <option value="outbound">Outbound</option>
          <option value="service">Service</option>
          <option value="1on1">1 on 1</option>
          <option value="mystery">Mystery Shop</option>
        </select>

        <!-- Date to Coach -->
        <div>
          <label for="coachdate">Date to coach this call:</label>
          <input type="date" id="coachdate" v-model="coachdate" />
        </div>

        <!-- Comment Textarea -->
        <div>
          <textarea
            v-model="comment"
            id="comment"
            rows="1"
            cols="50"
            placeholder="Enter comment"
          ></textarea>
        </div>

        <!-- Submit Button -->
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { userService, authenticationService } from "@/_services";
//import { config } from 'vue/types/umd';

export default {
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      dealers: "",
      people: "",
      calltype: "",
      coachdate: new Date().toISOString().substr(0, 10), // default to today's date
      comment: "",
      dealersList: [], // Populate this with data from your backend
      peopleList: [], // Populate this with data based on selected dealer
    };
  },
  methods: {
    fetchDealers() {
    //const apiUrl = process.env.VUE_APP_API_URL;
    fetch(`/api/dealers`)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    // Handle your data here
          this.dealersList = data;
  })
  .catch(error => {
    console.error('Error fetching dealers:', error);
  });
},
    submitForm() {
      const formData = {
        dealers: this.dealers,
        people: this.people,
        calltype: this.calltype,
        coachdate: this.coachdate,
        comment: this.comment,
        // Add other form data
      };
    
      fetch(`${apiUrl}/insert-scheduled-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.redirected) {
            window.location.href = response.url;
          }
        })
        .catch((error) => console.error("Error:", error));
    },
  },
  created() {
    userService
      .getById(this.currentUser.id)
      .then((email) => (this.userFromApi = email));
  },
  mounted() {
   this.fetchDealers();
  },
};
</script>
<style>
form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

select {
  margin-bottom: 10px; /* Adds some space between the select elements */
}
</style>
