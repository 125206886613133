import store from "../store/store.js"; // Adjust the path as necessary
import { mapGetters } from "vuex";
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Dealers from "src/pages/Dashboard/Dashboard/Dealers.vue";
import People from "src/pages/Dashboard/Dashboard/PeopleTable.vue";
import Inbound from "src/pages/Dashboard/Dashboard/Inbound.vue";
import Outbound from "src/pages/Dashboard/Dashboard/Outbound.vue";
import Service from "src/pages/Dashboard/Dashboard/Service.vue";
import Oneonone from "src/pages/Dashboard/Dashboard/Oneonone.vue";
import Profile from "src/pages/Dashboard/Dashboard/Profile.vue";
import Scripts from "src/pages/Dashboard/Dashboard/Scripts.vue";
import ScoreSheetCoachAssistantInbound from "src/components/ScoreSheetCoachAssistantInbound.vue";
import ScoreSheetCoachAssistantOutbound from "src/components/ScoreSheetCoachAssistantOutbound.vue";
import ScoreSheetCoachAssistantService from "src/components/ScoreSheetCoachAssistantService.vue";
import ScoreSheetCoachAssistantOneOnOne from "src/components/ScoreSheetCoachAssistantOneOnOne.vue"; 
// Pages
import User from "src/pages/Dashboard/Pages/UserProfile.vue";
import TimeLine from "src/pages/Dashboard/Pages/TimeLinePage.vue";
import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/pages/Dashboard/Tables/PaginatedTables.vue");
// Maps pages
const GoogleMaps = () => import("src/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import("src/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("src/pages/Dashboard/Maps/VectorMapsPage.vue");

// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");
// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");

// import { authenticationService } from '@/_services';
import { authenticationService } from "../_services/authentication.service";

import { Role } from "../_helpers/role";
let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables,
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap,
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps,
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock,
};
let currentView = {
  path: "/admin",
  component: DashboardLayout,
  meta: { requiresAuth: true }, // Add a meta property to require authentication
  children: [
    {
      path: "charts",
      name: "Charts",
      component: Charts,
    },
    {
      path: "dashboard",
      name: "Dashboard",
      component: Overview,
    },
      {
       path: "/scoring/inbound/:id",
      name: "ScoringSheetInbound",
      component: ScoreSheetCoachAssistantInbound,
      meta: { requiresAuth: true },
    },
    {
      path: "/scoring/outbound/:id",
      name: "ScoreSheetCoachAssistantOutbound",
      component: ScoreSheetCoachAssistantOutbound,
      meta: { requiresAuth: true },
    },
     {
      path: "/scoring/service/:id",
      name: "ScoreSheetCoachAssistantService",
      component: ScoreSheetCoachAssistantService,
      meta: { requiresAuth: true },
    },
    {
      path: "/scoring/1on1/:id",
      name: "ScoreSheetCoachAssistantOneOnOne",
      component: ScoreSheetCoachAssistantOneOnOne,
      meta: { requiresAuth: true },
    },
    {
      path: "dealers",
      name: "Dealers",
      component: Dealers,
    },
    {
      path: "people",
      name: "People",
      component: People,
    },
    {
      path: "inbound",
      name: "Inbound",
      component: Inbound,
    },
    {
      path: "outbound",
      name: "Outbound",
      component: Outbound,
    },
    {
      path: "service",
      name: "Service",
      component: Service,
    },
    {
      path: "oneonone",
      name: "Oneonone",
      component: Oneonone,
    },
     {
      path: "profile",
      name: "Profile",
      component: Profile,
    },
     {
      path: "scripts",
      name: "Scripts",
      component: Scripts,
    },
    {
      path: "ExtendedForms",
      name: "ExtendedForms",
      component: ExtendedForms,
    },
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/login", // Redirect the default route to the login page
  },
  {
    path: "/admin",
    redirect: "/admin/dashboard", // Redirect the default route to the login page
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  registerPage,
  lockPage,
  currentView,
  { path: "*", component: NotFound },
];

export default routes;
export function applyNavigationGuards(router) {
  router.beforeEach((to, from, next) => {
    const currentUser = authenticationService.currentUserValue;
    const { authorize } = to.meta;
    if (authorize) {
      if (!currentUser) {
        if (to.path !== "/login") {
          // Check if the current path is not already /login
          return next({ path: "/login", query: { returnUrl: to.path } });
        } else {
          return next(false); // Abort the navigation
        }
      } else if (authorize.length && !authorize.includes(currentUser.role)) {
        return next({ path: `admin/dashboard` });
      }
    } else if (to.path === "/login" && currentUser) {
      return next({ path: `admin/dashboard` });
    } else if (!currentUser && to.path !== "/login") {
      // Only redirect if not already on /login
      return next({ path: "/login" });
    }

    next();
  });
}
