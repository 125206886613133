<template>
    <span>Welcome to <b>IMPACTPHONECONCETPS</b>.</span>
</template>
<script>
  export default {
    name: 'notification-sample'
  }
</script>
<style>
</style>
