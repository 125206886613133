// store/modules/sessions.js

const state = {
  sessions: [],
  coachedSessions: [],
  scheduledSessionsCoach: [],
  notReviewedSessions: [],
  sessionsSalesPerson: [],
  people: [],
  dealers: [],
  total: 0,
  totalCoachedSessions: 0,
  totalScheduledSessions: 0,
  totalNotReviewedSessions: 0,
  totalSalesPersonSessions: 0,
};

const mutations = {
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
    state.total = sessions.length;
  },
  SET_COACHED_SESSIONS(state, coachedSessions) {
    state.coachedSessions = coachedSessions;
    state.totalCoachedSessions = coachedSessions.length;
  },
  SET_SCHEDULED_SESSIONS(state, scheduledSessions) {
    state.scheduledSessionsCoach = scheduledSessions;
    state.totalScheduledSessions = scheduledSessions.length;
  },
  SET_NOT_REVIEWED_SESSIONS(state, notReviewedSessions) {
    state.notReviewedSessions = notReviewedSessions;
    state.totalNotReviewedSessions = notReviewedSessions.length;
  },
  SET_SALESPERSON_SESSIONS(state, sessionsSalesPerson) {
    state.sessionsSalesPerson = sessionsSalesPerson;
    state.totalSalesPersonSessions = sessionsSalesPerson.length;
  },
  SET_PEOPLE(state, people) {
    state.people = people;
  },
  SET_DEALERS(state, dealers) {
    state.dealers = dealers;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
