// authModule.js
import store from './store.js'; // Update the path to your actual store file
// authModule.js

// State
const state = {
  userData: {},
};

// Mutations
const mutations = {
  SET_USER_DATA(state, data) {
    state.userData = data;
  },
};

// Actions
const actions = {
  setUserData({ commit }, data) {
    commit('SET_USER_DATA', data);
  },
};
// Getters
const getters = {
  // Define a getter for userData
  getUserData: (state) => {
    return state.userData;
  },
};

// Vuex Module
const authModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default authModule;
