// authModule.js
import store from './store.js'; // Update the path to your actual store file
// authModule.js

// State
const state = {
  peopleTable: {},
};

// Mutations
const mutations = {
  SET_PEOPLE_DATA(state, data) {
    state.peopleTable = data;
  },
};

// Actions
const actions = {
  setPeopleData({ commit }, data) {
    commit('SET_PEOPLE_DATA', data);
  },
};
// Getters
const getters = {
  // Define a getter for userData
  getPeopleData: (state) => {
    return state.peopleTable;
  },
};

// Vuex Module
const authModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default authModule;
