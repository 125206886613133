<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-auto w-100">

        <!-- Form for creating new 1-on-1 call -->
        <el-card>
          <h3>Start new 1 on 1 call:</h3>
          <el-form @submit.prevent="submitNewCall">

            <!-- Select Dealer -->
            <el-form-item label="Select Dealer">
              <el-select v-model="selectedDealer" placeholder="Select Dealer" @change="fetchPeople">
                <el-option v-for="dealer in dealersList" :key="dealer.dealerid" :label="dealer.dealername"
                  :value="dealer.dealerid">
                </el-option>
              </el-select>
            </el-form-item>

            <!-- Select Person -->
            <el-form-item label="Select Person">
              <el-select v-model="selectedPerson" placeholder="Select Person" :disabled="peopleList.length === 0">
                <el-option v-for="person in peopleList" :key="person.personid"
                  :label="person.firstname + ' ' + person.lastname" :value="person.personid">
                </el-option>
              </el-select>
            </el-form-item>

            <!-- Submit Button -->
            <el-button type="primary" @click="submitNewCall">Next</el-button>
          </el-form>
        </el-card>

        <br>

        <!-- Scoring Table for One-on-One calls -->
        <el-card>
          <el-table :data="pagedScoringData" style="width: 100%" border>
            <el-table-column label="Date" min-width="120">
              <template slot-scope="scope">
                {{ formatDate(scope.row.scoringtimestamp) }}
              </template>
            </el-table-column>
            <el-table-column prop="dealername" label="Dealer" width="200"></el-table-column>
            <!-- Person Column (use firstname and lastname to display the full name) -->
            <el-table-column label="Person" width="200">
              <template v-slot="scope">
                {{ scope.row.firstname }} {{ scope.row.lastname }}
              </template>
            </el-table-column> <el-table-column prop="coachname" label="Coach" width="200"></el-table-column>
            <el-table-column prop="totalscore" label="Score" width="120"></el-table-column>
            <el-table-column prop="reviewed" label="Reviewed" width="120"></el-table-column>
            <el-table-column fixed="right" label="Actions" width="120">
              <template v-slot="scope">
                <el-button type="text" @click="editCall(scope.row)">Edit</el-button>
                <el-button type="text" @click="deleteCall(scope.row)">Delete</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- Pagination -->
          <el-pagination @current-change="handlePageChange" :current-page="pagination.currentPage"
            :page-size="pagination.perPage" layout="total, prev, pager, next" :total="pagination.total"></el-pagination>
        </el-card>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Card, Form, FormItem, Select, Option, Button, Table, TableColumn, Pagination } from 'element-ui';
import ScoreSheetCoachAssistantOneOnOne from "../../../components/ScoreSheetCoachAssistantOneOnOne.vue";

export default {
  components: {
    'el-card': Card,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    'el-button': Button,
    'el-form': Form,
    'el-select': Select,
    'el-option': Option
  },
  data() {
    return {
      dealersList: [],
      peopleList: [],
      scoringData: [],
      selectedDealer: null,
      selectedPerson: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0
      },
    };
  },
  computed: {
    ...mapState('session', ['total']),
    pagedScoringData() {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.scoringData.slice(start, end);
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
    editCall(row) {
      this.$router.push({ name: 'ScoreSheetCoachAssistantOneOnOne', params: { id: row.scoringid } });
    },
    fetchDealers() {
      fetch('/api/dealers')
        .then(response => response.json())
        .then(data => {
          this.dealersList = data;
        })
        .catch(error => console.error('Error fetching dealers:', error));
    },
    fetchPeople(dealerId) {
      if (dealerId) {
        fetch(`/api/people?dealerid=${dealerId}`)
          .then(response => response.json())
          .then(data => {
            this.peopleList = data;
          })
          .catch(error => console.error('Error fetching people:', error));
      }
    },
    fetchScoringData() {
      fetch('/api/oneonone')
        .then(response => response.json())
        .then(data => {
          this.scoringData = data;
          this.pagination.total = data.length;
        })
        .catch(error => console.error('Error fetching scoring data:', error));
    },
    submitNewCall() {
      // Find the selected person in the people list
      const person = this.peopleList.find(person => person.personid === this.selectedPerson);

      // If the person exists, commit the person data to Vuex store
      if (person) {
        this.$store.commit('scoring/updateSelectedPerson', person);
      }

      // Check if both a dealer and a person are selected
      if (this.selectedDealer && this.selectedPerson) {
        // Generate a new scoring ID
        const newScoringId = this.generateNewScoringId();

        // Commit the new scoring ID to Vuex store
        this.$store.commit('scoring/updateNewScoringId', newScoringId);

        // Navigate to the scoring page with the new scoring ID as a route parameter
        this.$router.push({ name: 'ScoreSheetCoachAssistantOneOnOne', params: { id: newScoringId } });

        // Optionally, display the rating section after successful call submission
        this.showRatingSection = true;
      } else {
        // Show an alert if either the dealer or person is not selected
        alert("Please select both a dealer and a person.");
      }
    },
    generateNewScoringId() {
      if (this.scoringData.length > 0) {
        const maxScoringId = Math.max(...this.scoringData.map(call => call.scoringid));
        return maxScoringId + 1;
      } else {
        return 1; // Default ID if no calls exist
      }
    },

    resetSelectedCall() {
      this.showRatingSection = !this.showRatingSection;
    }
  },

  watch: {
    selectedDealer(newVal) {
      const dealer = this.dealersList.find(dealer => dealer.dealerid === newVal);
      if (dealer) {
        this.$store.commit('scoring/updateSelectedDealer', dealer);
      }
    },
    selectedPerson(newVal) {
      const person = this.peopleList.find(person => person.personid === newVal);
      if (person) {
        this.$store.commit('scoring/updateSelectedPerson', person);
      }
    }
  },
  mounted() {
    this.fetchDealers();
    this.fetchScoringData();
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 20px;
}
</style>
