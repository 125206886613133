import store from "../store/store.js"; // Update the path to your actual store file
// frontendwebapi.js
import Fuse from "fuse.js";
export async function fetchPeople(dealerId) {
  try {
    const response = await fetch(`/api/people?dealerid=${dealerId}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
        store.commit('session/SET_PEOPLE', data); // Commit to Vuex

    return data;
  } catch (error) {
    console.error("Error fetching people:", error);
    throw error;
  }
}

export async function fetchDealers() {
  try {
    const response = await fetch('/api/dealers');
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    store.commit('session/SET_DEALERS', data); // Commit to Vuex
    return data;
  } catch (error) {
    console.error("Error fetching dealers:", error);
    throw error;
  }
}

export async function fetchSessions() {
  try {
    const response = await fetch('/api/scheduled-sessions');
    if (!response.ok) {
      throw new Error('Error fetching sessions');
    }
    const data = await response.json();
     store.commit('session/SET_SESSIONS', data); // Commit to Vuex
    return data;
  } catch (error) {
    console.error('Error fetching sessions:', error);
    throw error;
  }
}

export async function fetchCoachedSessions(coachId) {
  try {
    const response = await fetch('/api/coached-sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coachId }),
    });
    if (!response.ok) {
      throw new Error('Error fetching coached sessions');
    }
    const data = await response.json();
     store.commit('session/SET_COACHED_SESSIONS', data); // Commit to Vuex
    return data;
  } catch (error) {
    console.error('Error fetching coached sessions:', error);
    throw error;
  }
}

export async function fetchScheduledSessions(coachId) {
  try {
    const response = await fetch('/api/scheduled-sessions-coach', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coachId }),
    });
    if (!response.ok) {
      throw new Error('Error fetching scheduled sessions');
    }
    const data = await response.json();
     store.commit('session/SET_SCHEDULED_SESSIONS', data); // Commit to Vuex
    return data;
  } catch (error) {
    console.error('Error fetching scheduled sessions:', error);
    throw error;
  }
}

export async function fetchNotReviewedSessions(coachId) {
  try {
    const response = await fetch('/api/not-reviewed-sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coachId }),
    });
    if (!response.ok) {
      throw new Error('Error fetching not reviewed sessions');
    }
    const data = await response.json();
     store.commit('session/SET_NOT_REVIEWED_SESSIONS', data); // Commit to Vuex
    return data;
  } catch (error) {
    console.error('Error fetching not reviewed sessions:', error);
    throw error;
  }
}
export function searchSessions(sessions, query) {
  if (query.trim() === '') {
    // If the search query is empty, return the original full dataset
    return sessions;
  } else {
    // Use Fuse.js to search in the sessions array
    const fuseOptions = {
      keys: ['dealername', 'firstname', 'lastname', 'coachname', 'calltype', 'personname'], // Fields to search
      includeScore: true
    };

    const fuse = new Fuse(sessions, fuseOptions);
    const results = fuse.search(query);
    // Map results to get only the items
    return results.map(result => result.item);
  }
}
export async function fetchSalesPersonSessions(salesPersonId, token) {
  try {
    const response = await fetch(`/api/salesperson-sessions?personId=${salesPersonId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Error fetching salesperson sessions');
    }
    const data = await response.json();
     store.commit('session/SET_SALESPERSON_SESSIONS', data); // Commit to Vue
    return data;
  } catch (error) {
    console.error('Error fetching salesperson sessions:', error);
    throw error;
  }
}
