<template>
  <div class="container-fluid">
    <h4 class="page-title">New Service Call</h4>
    <!-- Display notifications -->
    <l-alert v-if="alert.success" type="success" dismissible="">
      <span><b>Success - </b> Scoring data successfully submitted.</span>
    </l-alert>
    <l-alert v-if="alert.error" type="danger" dismissible="">
      <span><b>Error - </b> There was an error submitting the data. Please try again.</span>
    </l-alert>
    <l-alert v-if="alert.warning" type="warning" dismissible="">
      <span><b>Warning - </b> Form is missing some data. Please fill all required fields.</span>
    </l-alert>
    <!-- <span>
      for <b>{{ firstName }} {{ lastName }}</b> at <b>{{ dealerName }}</b>
    </span> -->
    <br />
    <b><span style="color:#cc0000;">red=no</span> &nbsp;&nbsp;&nbsp;
      <span style="color:#008800;">green=yes</span>
      &nbsp;&nbsp;&nbsp;
      <span style="color:#000000;">black=na</span></b>
    <br />
    <br />

    <div>
      <b>Total Score: {{ totalScore }}</b>
    </div>

    <!-- Greeting Section -->
    <question-section title="Greeting" score-title="Greeting Score" :questions="introductionQuestions"
      :computedScore="greetingScore"></question-section>

    <!-- Contact Info Section -->
    <question-section title="Contact Info" score-title="Contact Info Score" :questions="contactInfoQuestions"
      :computedScore="contactInfoScore"></question-section>

    <!-- Appointment Section -->
    <question-section title="Appointment" score-title="Appointment Score" :questions="appointmentQuestions"
      :computedScore="appointmentScore"></question-section>

    <!-- Call Quality Section -->
    <question-section title="Call Quality" score-title="Call Quality Score" :questions="callQualityQuestions"
      :computedScore="callQualityScore"></question-section>

    <!-- Closure Section -->
    <question-section title="Closure" score-title="Closure Score" :questions="closureQuestions"
      :computedScore="closureScore"></question-section>

    <!-- Customer Information -->
    <fg-input label="Customer's Name" type="text" v-model="customerName"></fg-input>
    <fg-input label="Customer's Phone" type="text" v-model="customerPhone"></fg-input>
    <fg-input label="Secret Code" type="text" v-model="secretCode"></fg-input>
    <fg-input label="Comments">
      <textarea class="form-control" placeholder="Comments" v-model="comments" rows="3"></textarea>
    </fg-input>
    <file-uploader @file-selected="onFileSelected"></file-uploader>
    <br />
    <l-button @click="sendScoring" type="primary" wide>Submit</l-button>
  </div>
</template>
<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form, RadioButton, RadioGroup } from 'element-ui'
import { mapActions, mapState } from 'vuex';

import {
  Switch as LSwitch,
} from 'src/components/index'
import FileUploader from './FileUploader';
import QuestionSection from './Questions/QuestionSection.vue';
import LAlert from 'src/components/Alert';
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
export default {
  name: 'ServiceScoreSheet',
  components: {

    LAlert,
    // intro,
    //'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    //'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    //'el-radio-group': RadioGroup,
    //'el-radio-button': RadioButton,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    //LSwitch,
    FileUploader,
    questionSection: QuestionSection
  },
  data() {
    return {
      user: localStorage.getItem('currentUser'),
      selectedSource: '',
      showRatingSection: false,
      operatorScore: 0,
      stpScore: 0,
      sellingTheApointement: 0,
      operatorComments: '',
      audioFile: null,
      // Define the alert object here
      alert: {
        success: false,
        error: false,
        warning: false,
      },

      introductionQuestions: [
        { id: "q1", score: false, text: "Did advisor say 'Thank you for choosing'?", type: "switch" },
        { id: "q2", score: false, text: "Did advisor use dealership name?", type: "switch" },
        { id: "q3", score: false, text: "Did advisor use first name only?", type: "switch" },
        { id: "q4", score: false, text: "Did advisor ask 'How may I help you'?", type: "switch" },
        { id: "q5", score: false, text: "Did advisor use proper tone and voice inflections?", type: "switch" },
      ],
      contactInfoQuestions: [
        { id: "q6", score: false, text: "Did service advisor ask if customer has ever been there for servicing?", type: "switch" },
        { id: "q7", score: false, text: "Did service advisor ask/confirm customer's contact info?", type: "switch" },
        { id: "q8", score: false, text: "Did service advisor thank the customer for coming back or welcome the new customer?", type: "switch" },
      ],
      appointmentQuestions: [
        { id: "q9", score: false, text: "Did service advisor offer 2 choices when setting the appointment?", type: "switch" },
        { id: "q10", score: false, text: "Did service advisor set an appointment?", type: "switch" },
        { id: "q11", score: false, text: "Did service advisor sound confident when asking for the appointment?", type: "switch" },
        { id: "q12", score: "na", text: "Did service advisor ask for the appointment more than once, if necessary?", type: "radio" },
      ],
      callQualityQuestions: [
        { id: "q13", score: false, text: "Was the service advisor understanding of the customer’s needs?", type: "switch" },
        { id: "q14", score: false, text: "Did service advisor sound knowledgeable?", type: "switch" },
      ],
      closureQuestions: [
        { id: "q15", score: false, text: "Did service advisor explain drop-off procedures?", type: "switch" },
        { id: "q16", score: false, text: "Did service advisor ask for the customer's best phone number?", type: "switch" },
        { id: "q17", score: false, text: "Did service advisor ask for or verify the customer's email?", type: "switch" },
        { id: "q18", score: false, text: "Did service advisor reconfirm appointment?", type: "switch" },
        { id: "q19", score: false, text: "Did service advisor inform them who to ask for when arriving?", type: "switch" },
        { id: "q20", score: false, text: "Did service advisor end the call with a 'thank you'?", type: "switch" },
      ],
    };
  },
  computed: {
    ...mapState('service', [
      'customerName', 'customerPhone', 'secretCode', 'comments'
    ]),

    customerName: {
      get() {
        return this.$store.state.service.customerName;
      },
      set(value) {
        this.$store.commit('service/updateCustomerName', value);
      }
    },

    customerPhone: {
      get() {
        return this.$store.state.service.customerPhone;
      },
      set(value) {
        this.$store.commit('service/updateCustomerPhone', value);
      }
    },

    secretCode: {
      get() {
        return this.$store.state.service.secretCode;
      },
      set(value) {
        this.$store.commit('service/updateSecretCode', value);
      }
    },

    comments: {
      get() {
        return this.$store.state.service.comments;
      },
      set(value) {
        this.$store.commit('service/updateComments', value);
      }
    },

    totalScore() {
      let total = this.greetingScore + this.contactInfoScore + this.appointmentScore + this.callQualityScore + this.closureScore;
      return (total / 5).toFixed(2);
    },
    greetingScore() {
      return this.calculateScore(this.introductionQuestions, 1);
    },
    contactInfoScore() {
      return this.calculateScore(this.contactInfoQuestions, 1.67);
    },
    appointmentScore() {
      let questionsUsed = this.appointmentQuestions[3].score !== "na" ? 4 : 3;
      let pointValue = questionsUsed === 4 ? 1.25 : 1.66;
      return this.calculateScore(this.appointmentQuestions.slice(0, 3), pointValue) + (this.appointmentQuestions[3].score === "yes" ? pointValue : 0);
    },
    callQualityScore() {
      return this.calculateScore(this.callQualityQuestions, 2.5);
    },
    closureScore() {
      return this.calculateScore(this.closureQuestions, 0.83);
    },
    allQuestions() {
      // Combine all question arrays into one array
      return [
        ...this.introductionQuestions,
        ...this.contactInfoQuestions,
        ...this.appointmentQuestions,
        ...this.callQualityQuestions,
        ...this.closureQuestions
      ];
    }

  },
  methods: {
    ...mapActions('service', ['updateScore', 'initializeScores', 'updateTotalScore']),
    // Convert the score string to Boolean for LSwitch
    convertToBoolean(score) {
      return score === 'yes';
    },
    // Convert Boolean value back to 'yes' or 'no' when switch is toggled


    // Method to set the score based on question type from the fetched data
    setQuestionScore(question, data) {
      if (question.type === 'switch') {
        question.score = data[`${question.id}_score`] === 'Y' ? 'yes' : 'no';
      } else if (question.type === 'radio') {
        const scoreValue = data[`${question.id}_score`];
        question.score = scoreValue === 'Y' ? 'yes' : (scoreValue === 'N' ? 'no' : 'na');
      }
    },

    getQuestionScore(data, questionId) {
      const score = data[`${questionId}_score`];
      return score === 'Y' ? 'yes' : (score === 'N' ? 'no' : 'na');
    },
    //potential mixin
    calculateScore(questions, value) {
      return questions.reduce((total, question) => total + (question.score ? value : 0), 0);
    },
    async fetchScoringData() {
      try {
        const scoringId = this.$route.params.id;  // Get the ID from the router parameter
        const response = await fetch(`/api/scoring/${scoringId}`);
        const newScoringId = this.$store.state.scoring.newScoringId; // Get newScoringId from the store

        // Proceed only if scoringId is not null or undefined
        if (newScoringId) {
          console.warn("Scoring ID is undefined or new");
          return;
        }

        if (!response.ok) throw new Error('Failed to fetch scoring data');
        const data = await response.json();
        this.populateScoringData(data);  // Populate data in the form fields
      } catch (error) {
        console.error("Error fetching scoring data:", error);
      }
    },
    populateScoringData(data) {
      // Update basic info
      this.$store.commit('service/updateCustomerName', data.customername);
      this.$store.commit('service/updateCustomerPhone', data.customerphone);
      this.$store.commit('service/updateSecretCode', data.secretcode);
      this.$store.commit('service/updateComments', data.comment1 || '');

      //       // Populate switch and radio type questions
      // const setQuestionScoreFromData = (questions) => {
      //   questions.forEach((question, index) => {
      //     const scoreKey = `q${index + 1}_score`;
      //     this.setQuestionScore(question, data);
      //     this.updateScore({ questionId: question.id, value: question.score });
      //   });
      // };

      this.introductionQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[`${question.id}_score`] === 'Y'; // Convert 'Y'/'N' to Boolean
        } else if (question.type === 'radio') {
          question.score = data[`${question.id}_score`] === 'Y' ? 'yes' : (data[`${question.id}_score`] === 'N' ? 'no' : 'na');
        }
      });
      this.contactInfoQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.appointmentQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.callQualityQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.closureQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });

      // Update the audio file and source details if available
      this.selectedSource = data.source || '';
      this.audioFile = {
        name: data.audiofile || '',
        folder: data.audiofolder || ''
      };
    },
    onFileSelected(file) {
      this.audioFile = file; // Handle the file locally in the parent component
    },
    async sendScoring() {
      const formData = new FormData();
      const audioFileState = this.audioFile;

      // Append the file if available and correctly retrieved
      if (audioFileState instanceof File) {
        formData.append('audiofile', audioFileState, audioFileState.name);
      } else {
        console.error('No file or incorrect file type');
      }

      // Append other form data
      formData.append('personid', this.$store.state.scoring.selectedPerson.personid);
      formData.append('dealerid', this.$store.state.scoring.selectedDealer.dealerid);
      formData.append('secretcode', this.$store.state.service.secretCode);
      formData.append('customername', this.$store.state.service.customerName);
      formData.append('customerphone', this.$store.state.service.customerPhone);
      formData.append('coachid', this.$store.state.auth.userData.userId);
      formData.append('coachname', this.$store.state.auth.userData.firstname + ' ' + this.$store.state.auth.userData.lastname);
      formData.append('comments', this.$store.state.service.comments);
      formData.append('source', this.selectedSource);
      formData.append('othersource', '');
      formData.append('appointmentset', this.appointmentQuestions.find(q => q.id === 'q11').score ? 'yes' : 'no');
      formData.append('totalscore', this.totalScore);

      // Append question scores

      // Convert Boolean to 'Y' or 'N' before appending the score
      [
        ...this.introductionQuestions,
        ...this.contactInfoQuestions,
        ...this.appointmentQuestions,
        ...this.callQualityQuestions,
        ...this.closureQuestions,
      ].forEach(question => {
        const score = question.type === 'switch'
          ? (question.score ? 'Y' : 'N')  // Switch scores are converted to 'Y' or 'N'
          : (question.type === 'radio'
            ? (question.score === 'yes' ? 'Y' : (question.score === 'no' ? 'N' : 'Z'))  // Radio scores: 'yes' -> 'Y', 'no' -> 'N', 'na' -> 'Z'
            : question.score);
        formData.append(`${question.id}_score`, score);
      });
      // Append category scores
      formData.append("cat1_score", this.greetingScore);
      formData.append("cat2_score", this.contactInfoScore);
      formData.append("cat3_score", this.appointmentScore);
      formData.append("cat4_score", this.callQualityScore);
      formData.append("cat5_score", this.closureScore);
      formData.append('cat1_name', 'Greeting');
      formData.append('cat2_name', 'Contact Info');
      formData.append('cat3_name', 'Appointment');
      formData.append('cat4_name', 'Call Quality/Customer Service');
      formData.append('cat5_name', 'Closure');
      formData.append('emailsent', 'N');
      formData.append('deleted', 'N');
      formData.append('coachable', 'Y');
      formData.append('questionscount', 20);
      formData.append('catscount', 5);

      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        const response = await fetch(`/api/process-form-service`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (result.success) {
          // Show success notification
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
            message: result.message
          });

          // Redirect to another route after successful submission
          this.$router.push('/admin/service');
        } else {
          // Show error notification
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: result.message
          });
        }
      } catch (error) {
        console.error('Error sending form data:', error);
        this.$notify({
          component: NotificationSample,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
          message: 'There was an error processing your request.'
        });
      }
    },
    handleScoreChange(questionId, score) {
      this.updateScore({ questionId, value: score });
    },
    // calculateOperatorScore() {
    //   // Implement scoring logic here
    //   this.operatorScore = this.questions.reduce((acc, question) => {
    //     return acc + (question.score === 'yes' ? 1 : 0); // Example scoring logic
    //   }, 0);
    // },
    calculateSTPScore() {
      const yesCount = this.questionsAppt.reduce((acc, question) => {
        return acc + (question.score === 'yes' ? 1 : 0);
      }, 0);

      switch (yesCount) {
        case 1:
          this.stpScore = 0.12;
          break;
        case 2:
          this.stpScore = 0.2;
          break;
        case 3:
          this.stpScore = 0.27;
          break;
        default:
          this.stpScore = 0;
      }
    }
    // other methods...
  },
  created() {
    const allQuestions = [
      ...this.introductionQuestions,
      ...this.contactInfoQuestions,
      ...this.appointmentQuestions,
      ...this.callQualityQuestions,
      ...this.closureQuestions
    ];
    this.initializeScores(allQuestions);

    // Dynamic watchers initialization
    const questionGroups = [
      'introductionQuestions',
      'contactInfoQuestions',  // renamed as per updated question structure
      'appointmentQuestions',
      'callQualityQuestions',
      'closureQuestions'
    ];

    questionGroups.forEach(group => {
      this[group].forEach(question => {
        this.$watch(
          () => question.score,
          (newVal) => {
            this.handleScoreChange(`${question.id}_score`, newVal);
          }
        );
      });
    });
  },
  mounted() {
    this.fetchScoringData();
  }
};

</script>
<style>
.question-container {
  margin-bottom: -22px;
  /* Adjust this value to increase or decrease spacing */
}
</style>
