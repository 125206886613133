<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <fade-render-transition>
              <card style="margin:0 auto;">
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <fg-input type="email" :error="errors[0]" label="Email address" name="email" v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider name="password" rules="required|min:5" v-slot="{ errors }">
                    <fg-input type="password" :error="errors[0]" name="password" label="Password" v-model="password">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd">
                    Login
                  </button>
                  <br />
                  <!-- <div class="forgot">
                    <router-link to="/register" class="card-category">Forgot your password?</router-link>
                  </div> -->
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div><!-- Success or Error Alert -->
      <l-alert v-if="alert.success" type="success" dismissible="">
        <span><b>Success - </b>{{ alert.message }}</span>
      </l-alert>
      <l-alert v-if="alert.error" type="danger" dismissible="">
        <span><b>Error - </b>{{ alert.message }}</span>
      </l-alert>
    </div>
  </auth-layout>

</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "../Pages/AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { authenticationService } from "../../../_services/authentication.service";
import { authenticateUser } from "../../../_helpers/authenticateUser";
import NotificationSample from "../Components/NotificationSample";
import lAlert from "src/components/Alert";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
    lAlert,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      submitted: false,
      loading: false,
      returnUrl: "/login",
      error: "",
      alert: {
        success: false,
        error: false,
        message: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      this.submitted = true;
      this.loading = true;

      try {
        // Call authenticateUser to handle login
        //const response = await authenticateUser(this.email, this.password);

        // Success: Use authenticationService for further processing
        authenticationService.login(this.email, this.password).then(
          (user) => {
            // Set success alert and message
            this.alert.success = true;
            this.alert.error = false;
            this.alert.message = 'Login successful!';

            // Show success notification
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
              message: 'Login successful!'
            });

            // Redirect to the admin dashboard on successful login
            this.$router.push('/admin/dashboard');
          },
          (error) => {
            // Handle error from authenticationService
            this.alert.success = false;
            this.alert.error = true;
            this.alert.message = 'Login failed in authentication service.';
            this.loading = false;
          }
        );
      } catch (error) {
        // Error handling based on the server response from authenticateUser
        if (error.response && error.response.data && error.response.data.error) {
          this.alert.success = false;
          this.alert.error = true;
          this.alert.message = error.response.data.error; // e.g., "Password is incorrect"
        } else {
          // Handle any unexpected errors (e.g., network issues)
          this.alert.success = false;
          this.alert.error = true;
          this.alert.message = 'An unexpected error occurred.';
        }

        // Stop loading when there's an error
        this.loading = false;
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  created() {
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.$router.push('/admin/dashboard');
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
