// In your Vuex store (e.g., store/modules/sessions.js)

const state = {
    selectedDealer: null,
    selectedPerson: null,
      operatorScoring: null, // or your default value
      newScoringId:null,
    // other states
  };

const mutations = {
  updateOperatorScoring(state, value) {
    state.operatorScoring = value;
  },
   updateNewScoringId(state, value) {
    state.newScoringId = value;
  },
    updateSelectedDealer(state, dealer) {
      state.selectedDealer = dealer;
    },
    updateSelectedPerson(state, person) {
      state.selectedPerson = person;
    },
    // other mutations
  };
  const actions = {
  setOperatorScoring({ commit }, value) {
    commit('updateOperatorScoring', value);
  },
  // other actions
};

// const actions = {
//   fetchSessions({ commit }) {
//     fetch('http://localhost:3000/api/scheduled-sessions')
//       .then(response => response.json())
//       .then(data => {
//         commit('SET_SESSIONS', data);
//       })
//       .catch(error => console.error('Error fetching sessions:', error));
//   },
// };

export default {
  namespaced: true,
  state,
  mutations,
};
