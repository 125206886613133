import { BehaviorSubject } from 'rxjs';
import { requestOptions, handleResponse } from '@/_helpers';
import Vue from 'vue';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

//const apiUrl = 'https://newdev.impactphoneconcepts.com';

export function login(email, password) {
    

    return fetch(`api/users/authenticate`, requestOptions.post({ email, password }))
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);
            }
            return user;
        });
}

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
