<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-auto w-100">
        <card>
          <div>
            <!-- Table -->
            <card>
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <!-- Search -->
                <el-input v-model="searchQuery" class="mb-3" placeholder="Search dealers" suffix-icon="el-icon-search"
                  @input="handleSearch">
                </el-input>
              </div>

              <el-table :data="filteredDealers" style="width: 100%;" border>
                <el-table-column prop="dealername" label="Dealer Name"></el-table-column>
                <el-table-column prop="dealercity" label="City"></el-table-column>
                <el-table-column prop="dealerstate" label="State"></el-table-column>
                <el-table-column prop="dealerurl" label="URL" width="180">
                  <template slot-scope="scope">
                    <a :href="scope.row.dealerurl" target="_blank">{{ scope.row.dealerurl }}</a>
                  </template>
                </el-table-column>
                <el-table-column prop="dealeractive" label="Active"></el-table-column>
              </el-table>
            </card>

            <!-- Pagination -->
            <l-pagination v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
              @current-change="handlePageChange">
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

// import { authenticationService } from "../../../_services/authentication.service.js";
// import { userService } from "../../../_services/user.service.js";
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination } from 'element-ui'
import Fuse from 'fuse.js';
// import { mapState } from "vuex";

export default {
  components: {
    // 'l-card': Card,
    'el-table': Table,
    'el-table-column': TableColumn,
    'l-pagination': Pagination,
    'el-input': Input,
    // 'el-button': Button,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      dealers: [],
      searchQuery: '',
      searchResult: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0
      },
    }
  },
  computed: {
    filteredDealers() {
      if (!this.searchQuery) return this.dealers;
      return this.dealers.filter((dealer) => dealer.dealername.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  watch: {


  },
  methods: {
    paginate(items) {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return items.slice(start, end);
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
    handleSearch() {
      if (this.searchQuery.trim() === '') {
        // If the search query is empty, reset to the full list from Vuex store
        this.dealerPageSearch = this.$store.state.dealers.dealerPage;
      } else {
        // Perform search using Fuse.js on Vuex state's sessions
        const fuse = new Fuse(this.$store.state.dealers.dealerPage, {
          keys: ["dealername", "dealercity", "dealerstate", "dealerurl", "dealeractive"],
          includeScore: true
        });
        const results = fuse.search(this.searchQuery);
        // Update the component's sessions with the search result
        this.dealerPageSearch = results.map(result => result.item);
        this.searchResult.push(this.dealerPageSearch);
      }
      // Adjust pagination based on filtered results
      this.pagination.currentPage = 1;
      this.pagination.total = this.dealerPageSearch.length;
    },
    fetchDealers() {
      fetch(`/api/dealer`) // Adjust this URL to your actual API endpoint
        .then(response => response.json())
        .then(data => {
          this.dealers = data;
          this.$store.dispatch('dealers/setDealersDataPage', data);
        })
        .catch(error => console.error('Error fetching dealers:', error));
    },


  },
  mounted() {
    this.fetchDealers();
  },
};
</script>
<style></style>
