import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './authModule'; // Adjust the path as necessary
import sessionModule from './sessionModule';
import peopleTableModule from './peopleTableModule';
import scoringSheetModule from './scoringSheetModule';
import inboundModule from './inboundModule';
import outboundModule from './outboundModule';
import serviceModule from './serviceModule';


import dealersModule from './dealersModule';
Vue.use(Vuex);

export default new Vuex.Store({
  // Define your state, mutations, actions, and modules here
  modules: {
    auth: authModule,
    session: sessionModule,
    dealers: dealersModule,
    people: peopleTableModule,
    scoring: scoringSheetModule,
    inbound: inboundModule,
    outbound: outboundModule,
    service: serviceModule,
  }
});