<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">100 Awesome Nucleo Icons</h4>
          <p class="card-category">Handcrafted by our friends from <a href="https://nucleoapp.com/?ref=1712">NucleoApp</a></p>
        </div>
        <div class="card-body all-icons">
          <div class="row">
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-air-baloon"></i>
                <p>nc-air-baloon</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-album-2"></i>
                <p>nc-album-2</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-alien-33"></i>
                <p>nc-alien-33</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-align-center"></i>
                <p>nc-align-center</p>
              </div>

            </div>

            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-align-left-2"></i>
                <p>nc-align-left-2</p>
              </div>

            </div>

            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-ambulance"></i>
                <p>nc-ambulance</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-android"></i>
                <p>nc-android</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-app"></i>
                <p>nc-app</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-apple"></i>
                <p>nc-apple</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-atom"></i>
                <p>nc-atom</p>
              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-attach-87"></i>
                <p>nc-attach-87</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-audio-92"></i>
                <p>nc-audio-92</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-backpack"></i>
                <p>nc-backpack</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-badge"></i>
                <p>nc-badge</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bag"></i>
                <p>nc-bag</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bank"></i>
                <p>nc-bank</p>

              </div>

            </div>

            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-battery-81"></i>
                <p>nc-battery-81</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bell-55"></i>
                <p>nc-bell-55</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bold"></i>
                <p>nc-bold</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bulb-63"></i>
                <p>nc-bulb-63</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bullet-list-67"></i>
                <p>nc-bullet-list-67</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-bus-front-12"></i>
                <p>nc-bus-front-12</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-button-pause"></i>
                <p>nc-button-pause</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-button-play"></i>
                <p>nc-button-play</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-button-power"></i>
                <p>nc-button-power</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-camera-20"></i>
                <p>nc-camera-20</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-caps-small"></i>
                <p>nc-caps-small</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-cart-simple"></i>
                <p>nc-cart-simple</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-cctv"></i>
                <p>nc-cctv</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-chart-bar-32"></i>
                <p>nc-chart-bar-32</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-chart-pie-35"></i>
                <p>nc-chart-pie-35</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-chart-pie-36"></i>
                <p>nc-chart-pie-36</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-chart"></i>
                <p>nc-chart</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-chat-round"></i>
                <p>nc-chat-round</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-check-2"></i>
                <p>nc-check-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-circle-09"></i>
                <p>nc-circle-09</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-circle"></i>
                <p>nc-circle</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-cloud-download-93"></i>
                <p>nc-cloud-download-93</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-cloud-upload-94"></i>
                <p>nc-cloud-upload-94</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-compass-05"></i>
                <p>nc-compass-05</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-controller-modern"></i>
                <p>nc-controller-modern</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-credit-card"></i>
                <p>nc-credit-card</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-delivery-fast"></i>
                <p>nc-delivery-fast</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-email-83"></i>
                <p>nc-email-83</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-email-85"></i>
                <p>nc-email-85</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-explore-2"></i>
                <p>nc-explore-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-fav-remove"></i>
                <p>nc-fav-remove</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-favourite-28"></i>
                <p>nc-favourite-28</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-globe-2"></i>
                <p>nc-globe-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-grid-45"></i>
                <p>nc-grid-45</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-headphones-2"></i>
                <p>nc-headphones-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-html5"></i>
                <p>nc-html5</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-istanbul"></i>
                <p>nc-istanbul</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-key-25"></i>
                <p>nc-key-25</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-layers-3"></i>
                <p>nc-layers-3</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-light-3"></i>
                <p>nc-light-3</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-lock-circle-open"></i>
                <p>nc-lock-circle-open</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-map-big"></i>
                <p>nc-map-big</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-mobile"></i>
                <p>nc-mobile</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-money-coins"></i>
                <p>nc-money-coins</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-note-03"></i>
                <p>nc-note-03</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-notes"></i>
                <p>nc-notes</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-notification-70"></i>
                <p>nc-notification-70</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-palette"></i>
                <p>nc-palette</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-paper-2"></i>
                <p>nc-paper-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-pin-3"></i>
                <p>nc-pin-3</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-planet"></i>
                <p>nc-planet</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-preferences-circle-rotate"></i>
                <p>nc-preferences-circle-rotate</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-puzzle-10"></i>
                <p>nc-puzzle-10</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-quote"></i>
                <p>nc-quote</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-refresh-02"></i>
                <p>nc-refresh-02</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-ruler-pencil"></i>
                <p>nc-ruler-pencil</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-satisfied"></i>
                <p>nc-satisfied</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-scissors"></i>
                <p>nc-scissors</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-send"></i>
                <p>nc-send</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-settings-90"></i>
                <p>nc-settings-90</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-settings-gear-64"></i>
                <p>nc-settings-gear-64</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-settings-tool-66"></i>
                <p>nc-settings-tool-66</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-simple-add"></i>
                <p>nc-simple-add</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-simple-delete"></i>
                <p>nc-simple-delete</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-simple-remove"></i>
                <p>nc-simple-remove</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-single-02"></i>
                <p>nc-single-02</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-single-copy-04"></i>
                <p>nc-single-copy-04</p>

              </div>

            </div>

            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-spaceship"></i>
                <p>nc-spaceship</p>

              </div>

            </div>

            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-square-pin"></i>
                <p>nc-square-pin</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-stre-down"></i>
                <p>nc-stre-down</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-stre-left"></i>
                <p>nc-stre-left</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-stre-right"></i>
                <p>nc-stre-right</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-stre-up"></i>
                <p>nc-stre-up</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-sun-fog-29"></i>
                <p>nc-sun-fog-29</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-support-17"></i>
                <p>nc-support-17</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-tablet-2"></i>
                <p>nc-tablet-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-tag-content"></i>
                <p>nc-tag-content</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-tap-01"></i>
                <p>nc-tap-01</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-time-alarm"></i>
                <p>nc-time-alarm</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-tv-2"></i>
                <p>nc-tv-2</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-umbrella-13"></i>
                <p>nc-umbrella-13</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-vector"></i>
                <p>nc-vector</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-watch-time"></i>
                <p>nc-watch-time</p>

              </div>

            </div>
            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="font-icon-detail">
                <i class="nc-icon nc-zoom-split"></i>
                <p>nc-zoom-split</p>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
<style>

</style>
