<template>
  <card>
    <div class="p-4"><b>Calls to be Coached:</b></div>

    <el-input v-model="localSearchQuery" class="mb-3" placeholder="Search sessions" suffix-icon="el-icon-search" @input="search">
    </el-input>

    <el-table :data="sessions" style="width: 100%;" border>
      <el-table-column prop="dealername" label="Dealer"></el-table-column>
      <el-table-column label="Person" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.firstname }} {{ scope.row.lastname }}
        </template>
      </el-table-column>
      <el-table-column prop="scheduledcalltype" label="Call Type"></el-table-column>
      <el-table-column label="Date" min-width="120">
        <template slot-scope="scope">{{ formatDate(scope.row.scheduledcoachdate) }}</template>
      </el-table-column>
      <el-table-column prop="scheduledcomment" label="Comment"></el-table-column>
      <el-table-column label="Click to Coach">
        <template slot-scope="scope">
          <el-button type="text" @click="$emit('coach-click', scope.row)">Click to coach</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Click if Uncoachable">
                <template slot-scope="scope">
                  <el-button type="text"  @click="$emit('uncoachable-click', scope.row)">Click if uncoachable</el-button>
                </template>
        </el-table-column>
    </el-table>

    <l-pagination
      :current-page="localPagination.currentPage"
      :page-size="localPagination.perPage"
      layout="total, prev, pager, next"
      :total="localPagination.total"
      @current-change="onPageChange"
    >
    </l-pagination>
  </card>
</template>

<script>
import { Table, TableColumn, Pagination, Input, Card, Button } from "element-ui";

export default {
  name: "CallsToBeCoachedTable",
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    "l-pagination": Pagination,
    card: Card,
    "el-input": Input,
    "el-button": Button,
  },
  props: {
    sessions: Array,
    pagination: Object,
    searchQuery: String,
  },
  data() {
    return {
      localSearchQuery: this.searchQuery, // Local copy of searchQuery
      localPagination: { ...this.pagination }, // Local copy of pagination
    };
  },
  methods: {
    search() {
      this.$emit("search", this.localSearchQuery); // Emit event to parent
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    handleCoachClick(row) {
      this.$emit("coach-click", row); // Emit event to parent
    },
    onPageChange(newPage) {
      this.localPagination.currentPage = newPage;
      this.$emit("page-change", newPage); // Emit event to parent
    },
  },
  watch: {
    // Sync with prop changes
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    },
    pagination: {
      handler(newVal) {
        this.localPagination = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
