<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-md">
      <l-alert v-if="alert.success" type="success" dismissible="">
          <span><b>Success - </b> Scoring data successfully submitted.</span>
        </l-alert>
        <l-alert v-if="alert.error" type="danger" dismissible="">
          <span><b>Error - </b> There was an error submitting the data. Please try again.</span>
        </l-alert>
        <l-alert v-if="alert.warning" type="warning" dismissible="">
          <span><b>Warning - </b> Form is missing some data. Please fill all required fields.</span>
        </l-alert>
        <h2>Total Score: {{ totalScore }}</h2>
        <br><br>

        <question-section title="Introduction, Reason for Call and Authorization" score-title="Introduction Score"
          :questions="introductionQuestions" :computedScore="introductionScore"></question-section>
        <br><br>

        <question-section title="Qualifying" score-title="Qualifying Score" :questions="qualifyingQuestions"
          :computedScore="qualifyingScore"></question-section>
        <br><br>

        <question-section title="Setting the Appointment" score-title="Appointment Score"
          :questions="settingAppointmentQuestions" :computedScore="settingAppointmentScore"></question-section>
        <br><br>

        <question-section title="Closing Out the Call" score-title="Closing Score" :questions="closingCallQuestions"
          :computedScore="closingCallScore"></question-section>
        <br><br>

        <question-section title="Directions to Dealership" score-title="Directions Score"
          :questions="directionsDealershipQuestions" :computedScore="directionsDealershipScore"></question-section>
        <br><br>

        <question-section title="Future Interaction" score-title="Future Score" :questions="futureInteractionQuestions"
          :computedScore="futureInteractionScore"></question-section>
        <br><br>

        <!-- Additional Components and Inputs for user information -->
        <div>
          <fg-input label="Customer's Name" type="text" v-model="customerName"></fg-input>
          <fg-input label="Customer's Phone" type="text" v-model="customerPhone"></fg-input>
          <fg-input label="Secret Code" type="text" v-model="secretCode"></fg-input>
          <fg-input label="Comments">
            <textarea class="form-control" placeholder="Comments" v-model="comments" rows="3"></textarea>
          </fg-input>
          <file-uploader @file-selected="onFileSelected"></file-uploader>
          <br>
          <l-button @click="sendScoring()" type="primary" wide>Submit</l-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form, RadioButton, RadioGroup } from 'element-ui'
import { mapActions, mapState } from 'vuex';

import {
  Switch as LSwitch,
} from 'src/components/index'
import FileUploader from './FileUploader';
import QuestionSection from './Questions/QuestionSection.vue';
import LAlert from 'src/components/Alert';
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
export default {
  name: 'OutboundScoreSheet',
  components: {
    LAlert,
    // intro,
    //'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    //'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    //'el-radio-group': RadioGroup,
    //'el-radio-button': RadioButton,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    //LSwitch,
    FileUploader,
    questionSection: QuestionSection
  },
  data() {
    return {
      user: localStorage.getItem('currentUser'),
      selectedSource: '',
      showRatingSection: false,
      operatorScore: 0,
      stpScore: 0,
      sellingTheApointement: 0,
      operatorComments: '',
      audioFile: null,
        alert: {
        success: false,
        error: false,
        warning: false,
      },
      introductionQuestions: [
        { id: 'q1', score: false, text: 'Did sales person ask for customer?', type: 'switch' },
        { id: 'q2', score: false, text: 'Did sales person introduce themselves?', type: 'switch' },
        { id: 'q3', score: false, text: 'Did sales person give customer dealership name?', type: 'switch' },
        { id: 'q4', score: false, text: 'Did sales person say, "The reason for my call is…"?', type: 'switch' },
        { id: 'q5', score: false, text: 'Did sales person sound enthusiastic?', type: 'switch' },
        { id: 'q6', score: false, text: 'Did sales person ask if they were calling at a good time?', type: 'switch' }
      ],
      qualifyingQuestions: [
        { id: 'q7', score: false, text: 'Did sales person confirm vehicle of interest?', type: 'switch' },
        { id: 'q8', score: false, text: 'Did sales person make customer feel good about vehicle of interest?', type: 'switch' },
        { id: 'q9', score: false, text: 'Did sales person inquire about other vehicles?', type: 'switch' }
      ],
      settingAppointmentQuestions: [
        { id: 'q10', score: false, text: 'Did salesperson ask for the appointment?', type: 'switch' },
        { id: 'q11', score: false, text: 'Did the salesperson set an appointment?', type: 'switch' },
        { id: 'q12', score: false, text: 'Did the salesperson ask with either or questions?', type: 'switch' },
        { id: 'q13', score: false, text: 'Did the salesperson ask with authority?', type: 'switch' }
      ],
      closingCallQuestions: [
        { id: 'q14', score: false, text: 'Did salesperson have the customer write down their info?', type: 'switch' },
        { id: 'q15', score: 'na', text: 'Did salesperson repeat appointment time?', type: 'radio' },
        { id: 'q16', score: 'na', text: 'Did salesperson ask for customer to call if running late?', type: 'radio' }
      ],
      directionsDealershipQuestions: [
        { id: 'q17', score: false, text: 'Did salesperson ask if customer knew where to go?', type: 'switch' },
        { id: 'q18', score: false, text: 'Did salesperson give directions?', type: 'switch' }
      ],
      futureInteractionQuestions: [
        { id: 'q19', score: 'na', text: 'Did salesperson ask customer when they should follow up?', type: 'radio' },
        { id: 'q20', score: 'na', text: 'Did salesperson set a time?', type: 'radio' }
      ],
    };
  },
  computed: {
    ...mapState('outbound', [
      'customerName', 'customerPhone', 'secretCode', 'comments'
    ]),

    customerName: {
      get() {
        return this.$store.state.outbound.customerName;
      },
      set(value) {
        this.$store.commit('outbound/updateCustomerName', value);
      }
    },

    customerPhone: {
      get() {
        return this.$store.state.outbound.customerPhone;
      },
      set(value) {
        this.$store.commit('outbound/updateCustomerPhone', value);
      }
    },

    secretCode: {
      get() {
        return this.$store.state.outbound.secretCode;
      },
      set(value) {
        this.$store.commit('outbound/updateSecretCode', value);
      }
    },

    comments: {
      get() {
        return this.$store.state.outbound.comments;
      },
      set(value) {
        this.$store.commit('outbound/updateComments', value);
      }
    },

    totalScore() {
      const futureInteractionScoreFixed = this.futureInteractionScore >= 2.5 ? 0.83 : 0
      const scores = [
        this.introductionScore / 5,
        this.qualifyingScore / 5,
        this.settingAppointmentScore / 5,
        this.closingCallScore / 5,
        this.directionsDealershipScore / 5,
        // to finish this score fix below 
        futureInteractionScoreFixed
      ];
      const total = scores.reduce((acc, score) => acc + score, 0);
      return total.toFixed(2);
    },
    introductionScore() {
      let score = this.introductionQuestions.filter(q => q.score).length * 0.84;
      return score > 5 ? 5.00 : score;
    },

    qualifyingScore() {
      let score = this.qualifyingQuestions.filter(q => q.score).length * 1.67;
      return score > 5 ? 5.00 : score;
    },

    // if set appointement + 2 olese plus one , only q11 
    settingAppointmentScore() {
      return this.settingAppointmentQuestions.reduce((total, question) => {
        if (question.type === 'switch' && question.id === 'q11' && question.score) {
          return total + 2; // 3 points for a correct radio selection.
        } else if (question.type === 'switch' && question.score) {
          return total + 1; // 2 points for each correct switch question.
        }
        return total;
      }, 0);
    },
    closingCallScore() {
      let switchQuestions = this.closingCallQuestions.filter(q => q.type === 'switch' && q.score);
      let radioQuestions = this.closingCallQuestions.filter(q => q.type === 'radio' && q.score === 'yes');

      // If any switch question is answered, give 5 points immediately
      if (switchQuestions.length > 0) {
        return 5;
      }

      // Calculate radio questions score
      let radioScore = 0;
      if (radioQuestions.length > 0) {
        radioScore += 2.5; // First 'radio' question is 2.5 points
      }
      if (radioQuestions.length > 1) {
        radioScore += 0.84; // Second 'radio' question is 0.84 points
      }

      // Ensure the maximum combined score for radio questions is 3.34
      if (radioScore > 3.34) {
        radioScore = 3.34;
      }

      return radioScore;
    },


    directionsDealershipScore() {
      return this.directionsDealershipQuestions.filter(q => q.score).length * 2.5;
    },

    futureInteractionScore() {
      let score = this.futureInteractionQuestions.filter(q => q.score === 'yes').length * 2.5; // 3 points for 'yes' in radio type questions.
      return score > 5 ? 5.00 : score
    }
  },
  methods: {
    ...mapActions('outbound', ['updateScore', 'initializeScores', 'updateTotalScore']),
    //potential mixin

    async fetchScoringData() {
      try {
        const scoringId = this.$route.params.id;  // Get the ID from the router parameter
        const response = await fetch(`/api/scoring/${scoringId}`);
        const newScoringId = this.$store.state.scoring.newScoringId; // Get newScoringId from the store

        // Proceed only if scoringId is not null or undefined
        if (newScoringId) {
          console.warn("Scoring ID is undefined or new");
          return;
        }

        if (!response.ok) throw new Error('Failed to fetch scoring data');
        const data = await response.json();
        this.populateScoringData(data);  // Populate data in the form fields
      } catch (error) {
        console.error("Error fetching scoring data:", error);
      }
    },
    populateScoringData(data) {
      // Update basic info
      this.$store.commit('outbound/updateCustomerName', data.customername);
      this.$store.commit('outbound/updateCustomerPhone', data.customerphone);
      this.$store.commit('outbound/updateSecretCode', data.secretcode);
      this.$store.commit('outbound/updateComments', data.comment1 || '');

      // Update each question group with appropriate scoring data
      this.introductionQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.qualifyingQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.settingAppointmentQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.closingCallQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.directionsDealershipQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.futureInteractionQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });


      // Update category scores directly tied to UI elements or computed properties
      this.selectedSource = data.source;
      this.audioFile = {
        name: data.audiofile,
        folder: data.audiofolder
      };

      // Assuming operatorScore is a computed property based on `questions` array
      this.questions.forEach((question, index) => {
        if (data[`q${index + 1}_score`]) {
          question.score = data[`q${index + 1}_score`] === 'Y' ? 'yes' : (data[`q${index + 1}_score`] === 'N' ? 'no' : 'na');
        }
      });

      // Trigger any necessary re-computation or updates
      this.calculateOperatorScore();
    },
    onFileSelected(file) {
      this.audioFile = file; // Handle the file locally in the parent component
    },
    async sendScoring() {
      const formData = new FormData();
      const audioFileState = this.audioFile;

      // Append the file if available and correctly retrieved
      if (audioFileState instanceof File) {
        formData.append('audiofile', audioFileState, audioFileState.name);
      } else {
        console.error('No file or incorrect file type');
      }

      // Append other form data
      formData.append('personid', this.$store.state.scoring.selectedPerson.personid);
      formData.append('dealerid', this.$store.state.scoring.selectedDealer.dealerid);
      formData.append('secretcode', this.$store.state.outbound.secretCode);
      formData.append('customername', this.$store.state.outbound.customerName);
      formData.append('customerphone', this.$store.state.outbound.customerPhone);
      formData.append('coachid', this.$store.state.auth.userData.userId);
      formData.append('coachname', this.$store.state.auth.userData.firstname + ' ' + this.$store.state.auth.userData.lastname);
      formData.append('comments', this.$store.state.outbound.comments);
      formData.append('source', this.selectedSource);
      formData.append('othersource', '');
      formData.append('appointmentset', this.settingAppointmentQuestions.find(q => q.id === 'q11').score ? 'yes' : 'no');
      formData.append('totalscore', this.totalScore);

      // Append question scores
      [
        ...this.introductionQuestions,
        ...this.qualifyingQuestions,
        ...this.settingAppointmentQuestions,
        ...this.closingCallQuestions,
        ...this.directionsDealershipQuestions,
        ...this.futureInteractionQuestions,
      ].forEach(question => {
        const score = question.type === 'switch' ? (question.score ? 'Y' : 'N') :
          (question.type === 'radio' ? (question.score === 'yes' ? 'Y' : (question.score === 'no' ? 'N' : 'Z')) : question.score);
        formData.append(`${question.id}_score`, score);
      });

      // Append category scores
      formData.append('cat1_score', this.introductionScore);
      formData.append('cat2_score', this.qualifyingScore);
      formData.append('cat3_score', this.settingAppointmentScore);
      formData.append('cat4_score', this.closingCallScore);
      formData.append('cat5_score', this.directionsDealershipScore);
      formData.append('cat6_score', this.futureInteractionScore);
      formData.append('cat1_name', 'Introduction, Reason, Authorization');
      formData.append('cat2_name', 'Qualifying');
      formData.append('cat3_name', 'Setting the Appointment');
      formData.append('cat4_name', 'Closing Out the Call');
      formData.append('cat5_name', 'Directions to Dealership');
      formData.append('cat6_name', 'Future');
      formData.append('emailsent', 'N');
      formData.append('deleted', 'N');
      formData.append('coachable', 'Y');
      formData.append('questionscount', 20);  // Adjust based on actual question count
      formData.append('catscount', 6); // Adjust based on actual categories

      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        const response = await fetch(`/api/process-form-outbound`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (result.success) {
          // Success alert
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
            message: result.message
          });

          // Redirect or reload if necessary
          this.$router.push('/admin/inbound');
        } else {
          // Error alert
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: result.message
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.$notify({
          component: NotificationSample,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
          message: 'There was an error processing your request.'
        });
      }
    },
    handleScoreChange(questionId, score) {
      this.updateScore({ questionId: `${questionId}_score`, value: score });
    },
    calculateOperatorScore() {
      // Implement scoring logic here
      this.operatorScore = this.questions.reduce((acc, question) => {
        return acc + (question.score === 'yes' ? 1 : 0); // Example scoring logic
      }, 0);
    },
    calculateSTPScore() {
      const yesCount = this.questionsAppt.reduce((acc, question) => {
        return acc + (question.score === 'yes' ? 1 : 0);
      }, 0);

      switch (yesCount) {
        case 1:
          this.stpScore = 0.12;
          break;
        case 2:
          this.stpScore = 0.2;
          break;
        case 3:
          this.stpScore = 0.27;
          break;
        default:
          this.stpScore = 0;
      }
    }
    // other methods...
  },
  created() {
    const allQuestions = [
      ...this.introductionQuestions,
      ...this.qualifyingQuestions,
      ...this.settingAppointmentQuestions,
      ...this.closingCallQuestions,
      ...this.directionsDealershipQuestions,
      ...this.futureInteractionQuestion,
    ];
    this.initializeScores(allQuestions);

    // Dynamic watchers initialization
    const questionGroups = [
      'introductionQuestions',
      'qualifyingQuestions',
      'settingAppointmentQuestions',
      'closingCallQuestions',
      'directionsDealershipQuestions',
      'futureInteractionQuestion'
    ];

    questionGroups.forEach(group => {
      this[group].forEach(question => {
        this.$watch(
          () => question.score,
          (newVal) => {
            console.log(newVal, question.id);
            this.handleScoreChange(`${question.id}_score`, newVal);
          }
        );
      });
    });
  },
  mounted() {
    //this.fetchSources();
  },
  created() {
    this.fetchScoringData();
  }
};

</script>
<style>
.question-container {
  margin-bottom: -22px;
  /* Adjust this value to increase or decrease spacing */
}
</style>
