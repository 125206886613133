<template>
  <div class="container-fluid">
    <h4 class="page-title">1 on 1</h4>
    <!-- Display notifications -->
    <l-alert v-if="alert.success" type="success" dismissible="">
      <span><b>Success - </b> Scoring data successfully submitted.</span>
    </l-alert>
    <l-alert v-if="alert.error" type="danger" dismissible="">
      <span><b>Error - </b> There was an error submitting the data. Please try again.</span>
    </l-alert>
    <l-alert v-if="alert.warning" type="warning" dismissible="">
      <span><b>Warning - </b> Form is missing some data. Please fill all required fields.</span>
    </l-alert>
    <!-- <span>
      for <b>{{ firstName }} {{ lastName }}</b> at <b>{{ dealerName }}</b>
    </span> -->
    <!-- <br />
    <b><span style="color:#cc0000;">red=no</span> &nbsp;&nbsp;&nbsp;
      <span style="color:#008800;">green=yes</span>
      &nbsp;&nbsp;&nbsp;
      <span style="color:#000000;">black=na</span></b>
    <br />
    <br /> -->



    <fg-input label="Comments">
      <textarea class="form-control" placeholder="Comments" v-model="comments" rows="3"></textarea>
    </fg-input>
    <file-uploader @file-selected="onFileSelected"></file-uploader>
    <br />
    <l-button @click="sendScoring" type="primary" wide>Submit</l-button>
  </div>
</template>
<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form, RadioButton, RadioGroup } from 'element-ui'
import { mapActions, mapState } from 'vuex';


import FileUploader from './FileUploader';
import LAlert from 'src/components/Alert';
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
export default {
  name: 'OneOnOneScoreSheet',
  components: {

    LAlert,
    // intro,
    //'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    //'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    //'el-radio-group': RadioGroup,
    //'el-radio-button': RadioButton,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    //LSwitch,
    FileUploader,
  },
  data() {
    return {
      user: localStorage.getItem('currentUser'),
      selectedSource: '',
      showRatingSection: false,
      operatorScore: 0,
      stpScore: 0,
      sellingTheApointement: 0,
      operatorComments: '',
      audioFile: null,
      // Define the alert object here
      alert: {
        success: false,
        error: false,
        warning: false,
      },

    };
  },
  computed: {
    ...mapState('service', [
      'customerName', 'customerPhone', 'secretCode', 'comments'
    ]),

    customerName: {
      get() {
        return this.$store.state.service.customerName;
      },
      set(value) {
        this.$store.commit('service/updateCustomerName', value);
      }
    },

    customerPhone: {
      get() {
        return this.$store.state.service.customerPhone;
      },
      set(value) {
        this.$store.commit('service/updateCustomerPhone', value);
      }
    },

    secretCode: {
      get() {
        return this.$store.state.service.secretCode;
      },
      set(value) {
        this.$store.commit('service/updateSecretCode', value);
      }
    },

    comments: {
      get() {
        return this.$store.state.service.comments;
      },
      set(value) {
        this.$store.commit('service/updateComments', value);
      }
    },

    totalScore() {
      let total = this.greetingScore + this.contactInfoScore + this.appointmentScore + this.callQualityScore + this.closureScore;
      return (total / 5).toFixed(2);
    },
    greetingScore() {
      return this.calculateScore(this.introductionQuestions, 1);
    },
    contactInfoScore() {
      return this.calculateScore(this.contactInfoQuestions, 1.67);
    },
    appointmentScore() {
      let questionsUsed = this.appointmentQuestions[3].score !== "na" ? 4 : 3;
      let pointValue = questionsUsed === 4 ? 1.25 : 1.66;
      return this.calculateScore(this.appointmentQuestions.slice(0, 3), pointValue) + (this.appointmentQuestions[3].score === "yes" ? pointValue : 0);
    },
    callQualityScore() {
      return this.calculateScore(this.callQualityQuestions, 2.5);
    },
    closureScore() {
      return this.calculateScore(this.closureQuestions, 0.83);
    },
    allQuestions() {
      // Combine all question arrays into one array
      return [
        ...this.introductionQuestions,
        ...this.contactInfoQuestions,
        ...this.appointmentQuestions,
        ...this.callQualityQuestions,
        ...this.closureQuestions
      ];
    }

  },
  methods: {
    ...mapActions('oneonone', ['updateScore', 'initializeScores', 'updateTotalScore']),

    // Handle file selection from the FileUploader component
    onFileSelected(file) {
      this.audioFile = file;
    },

    // Method to send scoring data to the backend
    async sendScoring() {
      const formData = new FormData();

      // Check if audio file is uploaded
      if (this.audioFile instanceof File) {
        formData.append('audiofile', this.audioFile, this.audioFile.name);
      } else {
        console.error('No file or incorrect file type');
      }
      console.log(this.$store.state.auth.userData.userId);
      // Append form data to formData object
      formData.append('personid', this.$store.state.scoring.selectedPerson.personid);
      formData.append('dealerid', this.$store.state.scoring.selectedDealer.dealerid);
      formData.append('comments', this.comments);
      formData.append('coachid', this.$store.state.auth.userData.userId);
      formData.append('coachname', `${this.$store.state.auth.userData.firstname} ${this.$store.state.auth.userData.lastname}`);

      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;

        const response = await fetch(`/api/process-form-oneonone`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.success) {
          // Success alert
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
            message: result.message
          });

          // Redirect or reload if necessary
          this.$router.push('/admin/oneonone');
        } else {
          // Error alert
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: result.message
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.$notify({
          component: NotificationSample,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
          message: 'There was an error processing your request.'
        });
      }
    },
  // Convert the score string to Boolean for LSwitch
  convertToBoolean(score) {
    return score === 'yes';
  },
  // Convert Boolean value back to 'yes' or 'no' when switch is toggled


  // Method to set the score based on question type from the fetched data
  setQuestionScore(question, data) {
    if (question.type === 'switch') {
      question.score = data[`${question.id}_score`] === 'Y' ? 'yes' : 'no';
    } else if (question.type === 'radio') {
      const scoreValue = data[`${question.id}_score`];
      question.score = scoreValue === 'Y' ? 'yes' : (scoreValue === 'N' ? 'no' : 'na');
    }
  },

  getQuestionScore(data, questionId) {
    const score = data[`${questionId}_score`];
    return score === 'Y' ? 'yes' : (score === 'N' ? 'no' : 'na');
  },
  //potential mixin
  calculateScore(questions, value) {
    return questions.reduce((total, question) => total + (question.score ? value : 0), 0);
  },
  async fetchScoringData() {
    try {
      const scoringId = this.$route.params.id;  // Get the ID from the router parameter
      const response = await fetch(`/api/scoring/${scoringId}`);
      const newScoringId = this.$store.state.scoring.newScoringId; // Get newScoringId from the store

      // Proceed only if scoringId is not null or undefined
      if (newScoringId) {
        console.warn("Scoring ID is undefined or new");
        return;
      }

      if (!response.ok) throw new Error('Failed to fetch scoring data');
      const data = await response.json();
      this.populateScoringData(data);  // Populate data in the form fields
    } catch (error) {
      console.error("Error fetching scoring data:", error);
    }
  },
  populateScoringData(data) {
    // Update basic info
    this.$store.commit('service/updateCustomerName', data.customername);
    this.$store.commit('service/updateCustomerPhone', data.customerphone);
    this.$store.commit('service/updateSecretCode', data.secretcode);
    this.$store.commit('service/updateComments', data.comment1 || '');

    //       // Populate switch and radio type questions
    // const setQuestionScoreFromData = (questions) => {
    //   questions.forEach((question, index) => {
    //     const scoreKey = `q${index + 1}_score`;
    //     this.setQuestionScore(question, data);
    //     this.updateScore({ questionId: question.id, value: question.score });
    //   });
    // };

   

    // Update the audio file and source details if available
    this.selectedSource = data.source || '';
    this.audioFile = {
      name: data.audiofile || '',
      folder: data.audiofolder || ''
    };
  },
 
  
  handleScoreChange(questionId, score) {
    this.updateScore({ questionId, value: score });
  },
  // calculateOperatorScore() {
  //   // Implement scoring logic here
  //   this.operatorScore = this.questions.reduce((acc, question) => {
  //     return acc + (question.score === 'yes' ? 1 : 0); // Example scoring logic
  //   }, 0);
  // },
  calculateSTPScore() {
    const yesCount = this.questionsAppt.reduce((acc, question) => {
      return acc + (question.score === 'yes' ? 1 : 0);
    }, 0);

    switch (yesCount) {
      case 1:
        this.stpScore = 0.12;
        break;
      case 2:
        this.stpScore = 0.2;
        break;
      case 3:
        this.stpScore = 0.27;
        break;
      default:
        this.stpScore = 0;
    }
  }
  // other methods...
},
created() {

 
},
mounted() {
  this.fetchScoringData();
}
};

</script>
<style>
.question-container {
  margin-bottom: -22px;
  /* Adjust this value to increase or decrease spacing */
}
</style>
