import axios from 'axios';
import Vue from 'vue';

// Function to authenticate the user using the real backend
export async function authenticateUser(email, password) {
    try {
        if (!email || !password) {
            throw new Error('Email and password are required');
        }
        

        const response = await axios.post(`api/users/authenticate`, {
            email: email,
            password: password
        });
        // Handle the response data as needed in your application
        return response.data;
    } catch (error) {
        // Handle errors appropriately in your application
        console.error("Authentication failed", error);
        throw error;
    }
}
