<template>
  <router-view></router-view>
</template>

<script>
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
export default {
  mounted() {
    this.$notify(
      {
        component: NotificationSample,
        icon: 'nc-icon nc-app'
      })
  },
  created() {

    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const userData = JSON.parse(currentUser).user; // Assuming the structure you provided
      this.$store.commit('auth/SET_USER_DATA', userData);
    }
  }
}
</script>
