// authModule.js
import store from './store.js'; // Update the path to your actual store file
// authModule.js

// State
const state = {
  dealers: {},
  dealerPage: {}
};

// Mutations
const mutations = {
  UPDATE_DEALERS_DATA(state, data) {
    state.dealers = data;
  },
   UPDATE_DEALERS_DATA_PAGE(state, data) {
    state.dealerPage = data;
  },
};

// Actions
const actions = {
  setDealersData({ commit }, data) {
    commit('UPDATE_DEALERS_DATA', data);
  },
  setDealersDataPage({ commit }, data) {
    commit('UPDATE_DEALERS_DATA_PAGE', data);
  },
};
// Getters
const getters = {
  // Define a getter for userData
  getUserData: (state) => {
    return state.userData;
  },
};

// Vuex Module
const authModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default authModule;
