<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-auto w-100">
        <div v-if="isSalesPerson">
          <card title="Your Last 10 Scored Calls" subTitle="Line Chart">
            <div id="chartScoredCalls" class="ct-chart"></div>
          </card>
          <card>
            <el-input v-model="localSearchQuery" class="mb-3" placeholder="Search sessions" suffix-icon="el-icon-search"
              @input="handleSalesPersonSearch"></el-input>
            <el-table :data="filteredSalesPersonSessions" style="width: 100%;" border>
              <el-table-column label="View" width="100">
                <template slot-scope="scope">
                  <el-link type="text" @click="$router.push(`/scoring/${scope.row.calltype}/${scope.row.scoringid}`)">
                    - view -
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="Date" width="180">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.scoringtimestamp) }}
                </template>
              </el-table-column>
              <el-table-column prop="calltype" label="Call Type"></el-table-column>
              <el-table-column label="Score">
                <template slot-scope="scope">
                  <span v-if="scope.row.calltype !== '1on1'">{{ scope.row.totalscore.toFixed(2) }}</span>
                  <span v-else>N/A</span>
                </template>
              </el-table-column>
              <el-table-column prop="customername" label="Customer"></el-table-column>
              <el-table-column prop="reviewed" label="Reviewed" width="100">
                <template slot-scope="scope">
                  {{ scope.row.reviewed === 'Y' ? 'Yes' : 'No' }}
                </template>
              </el-table-column>
            </el-table>

            <l-pagination :current-page="localCurrentPage" :per-page="paginationSalesPerson.perPage"
              :total="paginationSalesPerson.total" @current-change="handleSalesPersonPageChange" />
          </card>
        </div>
        <!-- Coach Assistant Role -->
        <div v-if="isCoachAssistantOrSuperuserOrAdmin">
          <!-- Display notifications -->
          <l-alert v-if="alert.success" type="success" dismissible="">
            <span><b>Success - </b> Call is assigned</span>
          </l-alert>
          <l-alert v-if="alert.error" type="danger" dismissible="">
            <span><b>Error - </b> There was an error assigning the call</span>
          </l-alert>
          <l-alert v-if="alert.warning" type="warning" dismissible="">
            <span><b>Warning - </b> Form is missing some data. Please fill all required fields.</span>
          </l-alert>
          <!-- Form Component -->
          <coach-assistant-form :dealers-list="dealersList" :people-list="peopleList" :dealers.sync="dealers"
            :people="people" :calltype="calltype" :coachdate="coachdate" :comment="comment" @updateForm="updateForm"
            @submitForm="submitForm" />


          <!-- Table Component -->
          <coach-assistant-table :sessions="queriedData" :pagination="pagination" :search-query="searchQuery"
            @search="handleSearch" @delete="handleDelete" @page-change="handlePageChange" />

        </div>

        <!-- Coach Role -->
        <div v-if="isCoach">
          <!-- Calls to be Coached Table -->
          <calls-to-be-coached-table :sessions="queriedSessions" :pagination="paginationCoach"
            @page-change="handlePageChangeCoach" @coach-click="handleCoachClick" @search="handleScheduledSessionsSearch"
            @uncoachable-click="handleUncoachableClick" />

          <!-- Coached Sessions Table -->
          <coached-sessions-table :sessions="queriedCoachedSessions" :pagination="paginationCoachedSessions"
            @search="handleCoachedSessionsSearch" @page-change="handlePageChangeCoachedSessions"
            @view-click="handleViewClick" />

          <!-- Not Reviewed Table -->
          <not-reviewed-sessions-table :sessions="queriedNotReviewedSessions" :pagination="paginationNotReviewed"
            @search="handleNotReviewedSessionsSearch" @page-change="handlePageChangeNotReviewed" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticationService } from "../../../_services/authentication.service.js";
import { userService } from "../../../_services/user.service.js";
import Fuse from "fuse.js";
import { mapState, mapGetters } from "vuex";

// Import child components
import CoachAssistantForm from "./CoachAssistantForm.vue";
import CoachAssistantTable from "./CoachAssistantTable.vue";
import CoachedSessionsTable from "./CoachedSessionsTable.vue";
import notReviewedSessionsTable from "./NotReviewedSessionsTable.vue";
import CallsToBeCoachedTable from './CallsToBeCoachedTable.vue';
import LAlert from 'src/components/Alert';
import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
import { DatePicker, TimeSelect, Link, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination } from 'element-ui'
import { fetchDealers, searchSessions, fetchSessions, fetchCoachedSessions, fetchScheduledSessions, fetchNotReviewedSessions, fetchSalesPersonSessions, fetchPeople } from '../../../util/frontendwebapi.js';

export default {
  components: {
    LAlert,
    CoachAssistantForm,
    CoachAssistantTable,
    CoachedSessionsTable,
    notReviewedSessionsTable,
    CallsToBeCoachedTable,
    'el-table': Table,
    'el-table-column': TableColumn,
    'l-pagination': Pagination,
    'el-link': Link,
  },
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
      dealers: "",
      people: "",
      calltype: "",
      coachdate: new Date().toISOString().substr(0, 10),
      comment: "",


      // data + filters 
      dealersList: [],
      peopleList: [],
      filteredSessions: [],
      filteredSessionsSalesPerson: [],
      filteredScheduledSessionsCoach: [],
      filteredCoachedSessions: [],
      filteredNotReviewedSessions: [],
      sessions: [],
      sessionsSalesPerson: [],
      scheduledSessionsCoach: [],
      coachedSessions: [],
      notReviewedSessions: [],


      localCurrentPage: 1,
      localSearchQuery: "",

      //searchQueries 
      searchQuery: '',
      searchQueryScheduledSessions: '',
      searchQuerySessionSalesPerson: '',
      searchQueryCoachedSessions: '',
      searchQueryNotReviewed: '',

      searchResults: {
        coached: [],
        coach: [],
        notReviewed: []
      },

      alert: {
        success: false,
        error: false,
        warning: false,
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
      paginationCoachedSessions: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
      paginationNotReviewed: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
      paginationCoach: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
      paginationSalesPerson: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
      fuse: null,
    };
  },
  computed: {
    ...mapState("session", ["total"]),
    ...mapGetters('auth', ['getUserData']),
    isCoachAssistantOrSuperuserOrAdmin() {
      return this.getUserData && (
        this.getUserData.role === 'coach assistant' ||
        this.getUserData.role === 'superuser' || this.getUserData.role === 'admin'
      );
    },
    isCoach() {
      return this.getUserData && (
        this.getUserData.role === 'coach'
      );
    },
    isSalesPerson() {
      return this.getUserData && (
        this.getUserData.role === 'salesperson'
      );
    },
    queriedData() {
      // If there is a search query, use the filtered sessions, otherwise use the full dataset
      if (this.searchQuery.trim() === '') {
        return this.paginate(this.sessions); // No search query, return the full dataset
      } else {
        return this.paginate(this.filteredSessions); // Display the filtered results when searching
      }
    },
    queriedSessions() {
      if (this.searchQueryScheduledSessions.trim() === '') {
        return this.paginate(this.scheduledSessionsCoach, this.paginationCoach);
      } else {
        return this.paginate(this.filteredScheduledSessionsCoach, this.paginationCoach);
      }
    },
    queriedCoachedSessions() {
      if (this.searchQueryCoachedSessions.trim() === '') {
        return this.paginate(this.coachedSessions, this.paginationCoachedSessions);
      } else {
        return this.paginate(this.filteredCoachedSessions, this.paginationCoachedSessions);
      }
    },
    queriedNotReviewedSessions() {
      if (this.searchQueryNotReviewed.trim() === '') {
        return this.paginate(this.notReviewedSessions, this.paginationNotReviewed);
      } else {
        return this.paginate(this.filteredNotReviewedSessions, this.paginationNotReviewed);
      }
    },
    getUserData() {
      return this.$store.getters["auth/getUserData"]; // Get user data from Vuex store
    },
    filteredSalesPersonSessions() {
      // Filter sessions based on localSearchQuery and paginate them
      let filteredSessions = this.sessionsSalesPerson;

      if (this.localSearchQuery) {
        filteredSessions = filteredSessions.filter((session) =>
          session.customername.toLowerCase().includes(this.localSearchQuery.toLowerCase())
        );
      }

      // Paginate filtered sessions
      return this.paginate(filteredSessions, this.paginationSalesPerson);
    },
  },
  methods: {
    handleViewClick(row) {
      // Use the correct field from the row to get the scheduledid
      const scheduledid = row.scoringid; // Fallback if scheduledid isn't present
      const callType = row.calltype || row.callType; // Fallback if calltype isn't present
      if (!scheduledid) {
        console.error("Scheduled ID or Scoring ID is missing.");
        return;
      }

      // Now navigate to the specific URL format
      const url = `/scoring/${callType}/${scheduledid}`;
      this.$router.push(url); // Use Vue Router to navigate to the URL
    },

    handleSalesPersonSearch() {
      this.paginationSalesPerson.currentPage = 1; // Reset to the first page
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    handleSalesPersonPageChange(newPage) {
      // Update the current page in paginationSalesPerson
      this.paginationSalesPerson.currentPage = newPage;
      // No need to assign filteredSalesPersonSessions directly
    },
    async fetchScoredCalls() {
      const salesPersonId = this.$store.state.auth.userData.userId

      try {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;

        if (!token) {
          console.error('No token available');
          return;
        }

        // Fetch the last 10 scored calls from the Node.js backend with headers and authorization
        const response = await fetch(`/api/last-scored-calls?personId=${salesPersonId}`, {
          // You mentioned using DELETE method
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch scored calls');
        }

        const data = await response.json();
        this.scoredCallsData = data; // Store the fetched data
        this.initScoredCallsChart(); // Initialize the chart after fetching the data
      } catch (error) {
        console.error('Error fetching scored calls data:', error);
      }
    },

    initScoredCallsChart() {
      if (!this.scoredCallsData || !this.scoredCallsData.length) {
        console.error('No scored calls data available');
        return;
      }

      const labels = this.scoredCallsData.map(call =>
        new Date(call.scoringtimestamp).toLocaleDateString()
      ); // Extracting dates as labels

      const scores = this.scoredCallsData.map(call => call.totalscore); // Extracting scores as the dataset

      const dataScoredCalls = {
        labels: labels,
        series: [scores]
      };

      const optionsScoredCalls = {
        lineSmooth: false,
        axisY: {
          offset: 40,
          labelInterpolationFnc(value) {
            return `${value}`; // Show values as plain numbers
          }
        },
        low: 0, // Set the minimum value for the chart
        high: 5, // Set the maximum value for the chart
        height: '250px',
        classNames: {
          point: 'ct-point ct-green',
          line: 'ct-line ct-green'
        }
      };

      this.$Chartist.Line('#chartScoredCalls', dataScoredCalls, optionsScoredCalls);
    },

    async initCharts() {
      if (this.getUserData && this.getUserData.role === 'salesperson') {
        await this.fetchScoredCalls(); // Fetch data before initializing charts
      }
    },
    handleDelete(index, row) {
      const { scheduledid, scheduledguid } = row;
      const token = JSON.parse(localStorage.getItem('currentUser')).token;

      if (!scheduledid || !scheduledguid) {
        this.$notify({
          component: NotificationSample,
          icon: 'nc-icon nc-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
          message: 'Missing scheduledid or scheduledguid'
        });
        return;
      }

      fetch(`/api/delete-scheduled-sessions?scheduledid=${scheduledid}&scheduledguid=${scheduledguid}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => response.json()) // Convert the response to JSON
        .then(result => {

          if (result.success) {
            // Success notification
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
              message: result.message
            });
            // Optionally, remove the deleted row from the table
            this.sessions.splice(index, 1);
          } else {
            // Error notification
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger',
              message: result.error || 'An error occurred'
            });
          }
        })
        .catch(error => {
          console.error('Error deleting scheduled session:', error);
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: 'There was an error processing your request.'
          });
        });
    },
    updateForm({ dealers, people, calltype, coachdate, comment }) {
      this.dealers = dealers;
      this.people = people;
      this.calltype = calltype;
      this.coachdate = coachdate;
      this.comment = comment;
    },
    async submitForm(formData) {
      const token = JSON.parse(localStorage.getItem("currentUser")).token;
      fetch(`/api/insert-scheduled-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error && result.missing) {
            const missingFields = result.missing.join(', ');
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'warning',
              message: `Missing required fields: ${missingFields}`
            });
          } else if (result.success) {
            // Show success notification
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
              message: result.message
            });

            // Redirect to another route after successful submission
            this.$router.push('/admin/dashboard');
          } else {
            // Show error notification
            this.$notify({
              component: NotificationSample,
              icon: 'nc-icon nc-bell-55',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger',
              message: result.message
            });
          }

        })
        .catch((error) => {
          console.error('Error sending form data:', error);
          this.$notify({
            component: NotificationSample,
            icon: 'nc-icon nc-bell-55',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
            message: 'There was an error processing your request.'
          });
        });
      const sessionsData = await fetchSessions();
      this.sessions = sessionsData;
      this.pagination.total = this.sessions.length;
    },
    handleSearch(query) {
      this.searchQuery = query; // Update the search query from the child component

      // Call the external search function and update `filteredSessions`
      this.filteredSessions = searchSessions(this.sessions, query);
      //

    },

    // If you have separate searches for other session types like coachedSessions, call them similarly:
    handleCoachedSessionsSearch(query) {
      this.searchQueryCoachedSessions = query;
      this.filteredCoachedSessions = searchSessions(this.coachedSessions, query);
    },

    handleScheduledSessionsSearch(query) {
      this.searchQueryScheduledSessions = query;
      this.filteredScheduledSessionsCoach = searchSessions(this.scheduledSessionsCoach, query);
    },


    handleNotReviewedSessionsSearch(query) {
      this.searchQueryNotReviewed = query;
      this.filteredNotReviewedSessions = searchSessions(this.notReviewedSessions, query);
    },

    // handleSearch() {
    //   if (this.searchQuery.trim() === '') {
    //     // If the search query is empty, reset all arrays to their original values from Vuex store
    //     this.sessions = this.$store.state.session.sessions;
    //     this.coachedSessions = this.$store.state.session.coachedSessions;
    //     this.scheduledSessionsCoach = this.$store.state.session.scheduledSessionsCoach;
    //     this.notReviewedSessions = this.$store.state.session.notReviewedSessions;
    //   } else {
    //     // Fuse.js search logic for multiple arrays
    //     const fuseOptions = {
    //       keys: ["personname", "dealername", "calltype"], // The keys you want to search
    //       includeScore: true,
    //       threshold: 0.3, // Adjust based on your search accuracy needs
    //     };

    //     // Perform search on sessions
    //     const fuseSessions = new Fuse(this.$store.state.session.sessions, fuseOptions);
    //     const sessionResults = fuseSessions.search(this.searchQuery);
    //     this.sessions = sessionResults.map(result => result.item);

    //     // Perform search on coachedSessions
    //     const fuseCoachedSessions = new Fuse(this.$store.state.session.coachedSessions, fuseOptions);
    //     const coachedResults = fuseCoachedSessions.search(this.searchQuery);
    //     this.coachedSessions = coachedResults.map(result => result.item);

    //     // Perform search on scheduledSessionsCoach
    //     const fuseScheduledSessions = new Fuse(this.$store.state.session.scheduledSessionsCoach, fuseOptions);
    //     const scheduledResults = fuseScheduledSessions.search(this.searchQuery);
    //     this.scheduledSessionsCoach = scheduledResults.map(result => result.item);

    //     // Perform search on notReviewedSessions
    //     const fuseNotReviewedSessions = new Fuse(this.$store.state.session.notReviewedSessions, fuseOptions);
    //     const notReviewedResults = fuseNotReviewedSessions.search(this.searchQuery);
    //     this.notReviewedSessions = notReviewedResults.map(result => result.item);
    //   }
    // },



    handleCoachClick(row) {
      // Destructure row object to extract the needed properties
      const { scheduledid, scheduledcalltype, personid, firstname, lastname, dealerid, dealername } = row;

      // Manually construct the selectedDealer and selectedPerson objects
      const selectedDealer = {
        dealerid,
        dealername
      };

      const selectedPerson = {
        personid,
        firstname,
        lastname
      };

      // Ensure the necessary data is available
      if (!scheduledid || !scheduledcalltype || !personid || !dealerid) {
        console.error("Scheduled ID, Call Type, Person ID, or Dealer ID is missing.");
        return;
      }

      // Commit to Vuex: Update the selected person and dealer
      this.$store.commit('scoring/updateSelectedPerson', selectedPerson);
      this.$store.commit('scoring/updateSelectedDealer', selectedDealer);

      // Navigate based on the `scheduledcalltype` to the appropriate scoring page
      switch (scheduledcalltype.toLowerCase()) {
        case "inbound":
          this.$router.push({ name: 'ScoringSheetInbound', params: { id: scheduledid } });
          break;
        case "outbound":
          this.$router.push({ name: 'ScoreSheetCoachAssistantOutbound', params: { id: scheduledid } });
          break;
        case "service":
          this.$router.push({ name: 'ScoreSheetCoachAssistantService', params: { id: scheduledid } });
          break;
        case "1on1":
          this.$router.push({ name: 'ScoreSheetCoachAssistantOneOnOne', params: { id: scheduledid } });
          break;
        default:
          console.error("Invalid call type: " + scheduledcalltype);
      }
    },
    async handleUncoachableClick(row) {
      const { scheduledid, scheduledguid } = row;
      const user = JSON.parse(localStorage.getItem('currentUser'));
      const token = user && user.token;
      // Ensure the necessary data is available
      if (!scheduledid || !scheduledguid) {
        console.error("Scheduled ID or GUID is missing.");
        return;
      }

      // Make the API call to mark the session as uncoachable
      fetch('/api/uncoachable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ scheduledid, scheduledguid })
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            console.error(data.error);
          }
        })
        .catch(error => {
          console.error("Error marking session as uncoachable:", error);
        });
      this.scheduledSessionsCoach = await fetchScheduledSessions(this.getUserData.userId);

    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
    handlePageChangeCoach(newPage) {
      this.paginationCoach.currentPage = newPage;
    },
    handlePageChangeCoachedSessions(newPage) {
      this.paginationCoachedSessions.currentPage = newPage;
    },
    handlePageChangeNotReviewed(newPage) {
      this.paginationNotReviewed.currentPage = newPage;
    },
    paginate(items, paginationObj) {
      if (!paginationObj) {
        return items; // or handle the error here if paginationObj is missing
      }
      const start = (paginationObj.currentPage - 1) * paginationObj.perPage;
      const end = start + paginationObj.perPage;
      return items.slice(start, end);
    },
  },
  watch: {

    async dealers(newVal) {
      if (newVal !== "" && newVal !== "0") {
        try {
          const people = await fetchPeople(newVal); // Await the result
          this.peopleList = people; // Now assign the resolved data
        } catch (error) {
          console.error("Error fetching people:", error);
        }
      } else {
        this.peopleList = []; // Reset peopleList if no dealer is selected
      }
    }
  },
  created() {

    userService
      .getById(this.currentUser.id)
      .then((email) => (this.userFromApi = email));
  },
  async mounted() {
    const personId = this.getUserData.userId;
    const token = JSON.parse(localStorage.getItem("currentUser")).token;
    if (!personId) {
      console.error("No coachId found in userData");
      return;
    }
    try {
      this.dealersList = await fetchDealers();

      this.sessions = await fetchSessions();
      this.pagination.total = this.sessions.length;

      this.coachedSessions = await fetchCoachedSessions(personId); // Pass coachId
      this.paginationCoachedSessions.total = this.coachedSessions.length; // Set pagination total

      this.scheduledSessionsCoach = await fetchScheduledSessions(personId); // Pass coachId
      this.paginationCoach.total = this.scheduledSessionsCoach.length; // Set pagination total
      this.notReviewedSessions = await fetchNotReviewedSessions(personId); // Pass coachId
      this.paginationNotReviewed.total = this.notReviewedSessions.length; // Set pagination total
      this.sessionsSalesPerson = await fetchSalesPersonSessions(personId, token); // Pass salesPersonId and token
      this.paginationSalesPerson.total = this.sessionsSalesPerson.length; // Set pagination total
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    let chartist = await import('chartist');
    this.$Chartist = chartist.default ? chartist.default : chartist;
    this.initCharts();
  },
};
</script>
<style>
.ct-label.ct-horizontal.ct-end {

  font-size: 10px !important;
}

.ct-label.ct-horizontal.ct-start {

  font-size: 10px !important;
}
</style>
