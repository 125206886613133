<template>
  <div>
    <div class="category">{{ title }}</div>
    <span id="greetingsum" style="text-align:left; font-size:12px;">
      &nbsp;&nbsp;<b>({{ scoreTitle }}: {{ computedScore }})</b>
    </span>
    <br><br>
    <div v-for="question in questions" :key="question.id" class="question-container">
      <span v-if="question.type === 'switch'" @change="handleScoreChange(question.id, question.score)">
        <l-switch :disabled="isDisabled" v-model="question.score">
          <template #on><i class="fa fa-check"></i></template>
          <template #off><i class="fa fa-times"></i></template>
        </l-switch>
        <span class="question">{{ question.text }}</span>
      </span>
      <span v-else-if="question.type === 'radio'">
        <el-radio-group :disabled="isDisabled" v-model="question.score" @change="handleScoreChange(question.id, question.score)">
          <el-radio-button :label="'yes'">Yes</el-radio-button>
          <el-radio-button :label="'no'">No</el-radio-button>
          <el-radio-button :label="'na'">N/A</el-radio-button>
        </el-radio-group>
        <span class="question">{{ question.text }}</span>
      </span>
      <br><br>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form, RadioButton, RadioGroup } from 'element-ui'
import {
  Switch as LSwitch,
} from 'src/components/index'
export default {
    components: {
    // intro,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    // 'el-button': Button,
    // 'el-form': Form,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
  },
  props: {
    title: String,
    scoreTitle: String,
    questions: Array,
    computedScore: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isDisabled() {
      return this.$store.state.auth.userData.role === 'salesperson';
    }
    },
   methods: {
    ...mapActions(['updateScore']),
    
       handleScoreChange(questionId, score) {
      // Directly commit the change to Vuex store
      this.updateScore({ questionId, value: score });
    }
  }
};
</script>

<style scoped>
.question-container {
  margin-bottom: -20px;
}
</style>
