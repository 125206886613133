<template>
  <el-card>
    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <el-input v-model="searchQuery" class="mb-3" placeholder="Search people" suffix-icon="el-icon-search"
        @input="handleSearch">
      </el-input>
    </div>

    <el-table :data="peopleTable" style="width: 100%;" border>
      <el-table-column prop="firstname" label="First Name"></el-table-column>
      <el-table-column prop="lastname" label="Last Name"></el-table-column>
      <el-table-column prop="role" label="Role" :formatter="formatRole"></el-table-column>
      <el-table-column prop="dealername" label="Dealer"></el-table-column>
      <el-table-column prop="phone" label="Phone"></el-table-column>
      <el-table-column prop="active" label="Active"></el-table-column>
    </el-table>
        <!-- Pagination Component -->
    <el-pagination
      v-model="pagination.currentPage"
      :page-size="pagination.perPage"
      layout="total, prev, pager, next"
      :total="filteredPeople.length"
      @current-change="handlePageChange"
    ></el-pagination>
  </el-card>
</template>

<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination } from 'element-ui'
import { mapGetters } from 'vuex';
import Fuse from 'fuse.js';
//import { config } from 'vue/types/umd';

export default {
  components: {
    'el-card': Card,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    'el-input': Input,
    //`'el-button': Button,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  name: 'PeopleTable',

  data() {
    return {
      people: [],
      searchQuery: '',
      searchResult: [],
        pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters('people', ['getPeopleData']),
   
    filteredPeople() {
      if (!this.searchQuery) return this.people;
      return this.people.filter(person =>
        person.firstname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        person.lastname.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    formatRole() {
      if (this.$store.state.people.peopleTable) {
        return this.$store.state.people.peopleTable.role;
      }
      return false;
    },
    peopleTable() {
      return this.paginate(this.people, this.pagination);
    },

  },
  methods: {
     handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
      paginate(items, paginationObj) {
      if (!paginationObj) {
        return items; // or handle the error here if paginationObj is missing
      }
      const start = (paginationObj.currentPage - 1) * paginationObj.perPage;
      const end = start + paginationObj.perPage;
      return items.slice(start, end);
    },
    handleSearch() {
      if (this.searchQuery.trim() === '') {
        // If the search query is empty, reset to the full list from Vuex store
        this.people = this.$store.state.people.peopleTable;
      } else {
        // Perform search using Fuse.js on Vuex state's sessions
        const fuse = new Fuse(this.$store.state.people.peopleTable, {
          keys: ["active", "dealername", "email", "firstname", "lastname", "phone", "role", "title"],
          includeScore: true
        });
        const results = fuse.search(this.searchQuery);
        // Update the component's sessions with the search result
        this.people = results.map(result => result.item);
        this.searchResult.push(this.people);
      }
    },
    fetchPeople() {
      fetch(`/api/people-page`) // Adjust this URL to your actual API endpoint
        .then(response => response.json())
        .then(data => {
          this.people = data;
          this.$store.dispatch('people/setPeopleData', data);
          this.pagination.total = this.people.length;
          console.log(this.pagination.total);
        })
        .catch(error => console.error('Error fetching people:', error));
    },

  },
  mounted() {
    this.fetchPeople();
  },
}
</script>
