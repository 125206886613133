<template>
    <card>
        <div>
            <el-input v-model="localSearchQuery" class="mb-3" placeholder="Search sessions" suffix-icon="el-icon-search"
                @input="handleSearch"></el-input>

            <el-table :data="sessions" style="width: 100%;" border>
                <el-table-column prop="dealername" label="Dealer"></el-table-column>
                <el-table-column label="Person" min-width="120">
                    <template slot-scope="scope">
                        {{ scope.row.firstname }} {{ scope.row.lastname }}
                    </template>
                </el-table-column>
                <el-table-column prop="scheduledcalltype" label="Call Type"></el-table-column>
                <el-table-column label="Date" min-width="120">
                    <template slot-scope="scope">
                        {{ formatDate(scope.row.scheduledcoachdate) }}
                    </template>
                </el-table-column>
                <el-table-column prop="scheduledcomment" label="Comment"></el-table-column>
                <el-table-column fixed="right" label="Actions" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="$emit('delete', scope.$index, scope.row)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <l-pagination :current-page="localCurrentPage" :per-page="pagination.perPage" :total="pagination.total"
                @current-change="handlePageChange" />
        </div>
    </card>
</template>

<script>
import { Input, Table, TableColumn, Button, Pagination, Card } from "element-ui";

export default {
    name: "CoachAssistantTable",
    components: {
        "el-input": Input,
        "el-table": Table,
        "el-table-column": TableColumn,
        "el-button": Button,
        "l-pagination": Pagination,
        card: Card,
    },
    props: {
        sessions: Array,
        pagination: Object,
        searchQuery: String,
    },
    data() {
        return {
            localSearchQuery: this.searchQuery,
            localCurrentPage: this.pagination.currentPage, // Sync with pagination prop
        };
    },
    methods: {
        handleSearch() {
            this.$emit("search", this.localSearchQuery);
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        handlePageChange(newPage) {
            this.localCurrentPage = newPage;
            this.$emit('page-change', newPage); // Emit change to parent
        },
    },
};
</script>
