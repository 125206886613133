// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  scores: {},  // Stores scores with question ids as keys
  customerName: '',
  customerPhone: '',
  secretCode: '',
  comments: '',
  audioFile: {},
};

const mutations = {
   updateCustomerName(state, name) {
      state.customerName = name;
    },
    updateAudioFile(state, file) {
      state.audioFile = file;
    },
    updateCustomerPhone(state, phone) {
      state.customerPhone = phone;
    },
    updateSecretCode(state, code) {
      state.secretCode = code;
    },
    updateComments(state, comments) {
      state.comments = comments;
    },
  // Mutation to update the score of a question
    UPDATE_SCORE(state, { questionId, value }) {
    Vue.set(state.scores, questionId, value);
  },
  UPDATE_TOTAL_SCORE (state, totalScore) {
    state.totalScore = totalScore;
    },
   INITIALIZE_SCORES(state, questions) {
    questions.forEach(question => {
      const initialValue = question.type === 'switch' ? false : (question.type === 'radio' ? 'na' : null);
      Vue.set(state.scores, `${question.id}_score`, initialValue);
    });
  },
};

const actions = {
  updateScore({ commit }, payload) {
    commit('UPDATE_SCORE', payload);
  },
  updateTotalScore ({ commit }, totalScore) {
    commit('UPDATE_TOTAL_SCORE', totalScore);
  },
    initializeScores({ commit }, questions) {
    commit('INITIALIZE_SCORES', questions);
  }
};

// Vuex Module
const outboundModule = {
  namespaced: true,
  state,
  mutations,
  actions
};
export default outboundModule;
