

<template>
  <div class="container-fluid">
    <div class="row justify-content-start align-items-start">
      <div class="col-auto w-100">
        <div class="row d-flex justify-content-center align-items-start">
          <div class="col-md-6">
            <div class="header-text">
              <p>{{ $store.state.auth.userData.firstname + ' ' + $store.state.auth.userData.lastname }}</p>
              <p>{{ "Role:" + ' ' + $store.state.auth.userData.role }}</p>
              <p>{{ "Email:" + ' ' + $store.state.auth.userData.email }}</p>
              <hr>
            </div>
          </div>
          
          <div class="col-md-6 mr-auto">
            <form method="#" action="#">
              <card class="card-plain">
              <p>Scripts</p>
                <div>
                   <ul>
                    <li><a href="/static/pdf/Inbound Non-Specific Vehicle Script New or Used.pdf" target="_blank">Inbound Non-Specific Vehicle Script New or Used</a></li>
                    <li><a href="/static/pdf/Inbound Specific Vehicle Script New or Used.pdf" target="_blank">Inbound Specific Vehicle Script New or Used</a></li>
                    <li><a href="/static/pdf/Outbound Script.pdf" target="_blank">Outbound Script</a></li>
                    <li><a href="/static/pdf/Service Script.pdf" target="_blank">Service Script</a></li>
                    <li><a href="/static/pdf/Unsold Showroom Traffic.pdf" target="_blank">Unsold Showroom Traffic</a></li>
                  </ul>
                </div>
              
              </card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form } from 'element-ui'
//import { mapState } from "vuex";
import { FadeRenderTransition } from 'src/components/index'
import intro from '../../../components/intro.vue';
import ScoreSheetCoachAssistantInbound from '../../../components/ScoreSheetCoachAssistantInbound.vue';
export default {
  components: {
    
  },
  data() {
    return {
      
    };
  },
  computed: {
    //...mapState('session', ['total']),
    

 
  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    }
  },
  watch: {
  
  },
  created() {

  },
  beforeDestroy() {
    this.closeMenu()
  },
  mounted() {
  
  },
};
</script>

<style></style>
