<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-auto w-100">
        <el-card v-if="!showRatingSection">
          <h3>Start new outbound call:</h3>
          <el-form @submit.prevent="submitNewCall">
            <el-select class="pt-2" v-model="selectedDealer" placeholder="Select Dealer" @change="fetchPeople">
              <el-option v-for="dealer in dealersList" :key="dealer.dealerid" :label="dealer.dealername"
                :value="dealer.dealerid"></el-option>
            </el-select>

            <el-select class="pt-2" v-model="selectedPerson" placeholder="Select Person">
              <el-option v-for="person in peopleList" :key="person.personid"
                :label="person.firstname + ' ' + person.lastname" :value="person.personid"></el-option>
            </el-select>
            <div class="pt-4">
            <el-button type="primary" @click="submitNewCall">Next</el-button>
            </div>
          </el-form>
        </el-card>
        <el-card v-if="showRatingSection">
          <h3>SCORING:</h3>
          <l-button @click="resetSelectedCall" type="default" wide outline>
            <span class="btn-label">
              <i class="fa fa-arrow-left"></i>
            </span>
            Back
          </l-button>
          <intro></intro>
          <ScoreSheetCoachAssistantOutbound></ScoreSheetCoachAssistantOutbound>
        </el-card>
        <el-card>
          <el-table :data="pagedOutboundCalls" style="width: 100%" border>
            <el-table-column prop="scoringdate" label="Date" width="180"></el-table-column>
            <el-table-column prop="dealername" label="Dealer" width="200"></el-table-column>
            <el-table-column prop="personname" label="Person" width="200"></el-table-column>
            <el-table-column prop="coachname" label="Coach" width="200"></el-table-column>
            <el-table-column prop="totalscore" label="Score" width="120"></el-table-column>
            <el-table-column prop="reviewed" label="Reviewed" width="120"></el-table-column>
            <el-table-column fixed="right" label="Actions" width="100">
              <template v-slot="scope">
                <el-button type="text" @click="editCall(scope.row)">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="handlePageChange" :current-page="pagination.currentPage"
            :page-size="pagination.perPage" layout="total, prev, pager, next" :total="pagination.total">
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import intro from '../../../components/intro.vue';
import ScoreSheetCoachAssistantOutbound from "../../../components/ScoreSheetCoachAssistantOutbound.vue";
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form } from 'element-ui'

export default {
  components: {
    intro,
    ScoreSheetCoachAssistantOutbound,
    'el-card': Card,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    'el-button': Button,
    'el-form': Form,
    'el-select': Select,
    'el-option': Option
  },
  data() {
    return {
      dealersList: [],
      peopleList: [],
      scoringData: [],
      selectedDealer: null,
      selectedPerson: null,
      showRatingSection: false,
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0
      }
    };
  },
  computed: {
    ...mapState('session', ['total']),
    pagedOutboundCalls() {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.scoringData.slice(start, end);
    }
  },
  methods: {
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
    editCall(row) {
      this.$router.push({ name: 'ScoreSheetCoachAssistantOutbound', params: { id: row.scoringid } });
    },
    fetchDealers() {
      fetch('/api/dealers')
        .then(response => response.json())
        .then(data => this.dealersList = data)
        .catch(error => console.error('Error fetching dealers:', error));
    },
    fetchPeople(dealerId) {
      fetch(`/api/people?dealerid=${dealerId}`)
        .then(response => response.json())
        .then(data => this.peopleList = data)
        .catch(error => console.error('Error fetching people:', error));
    },
    async fetchScoringData() {
      fetch('/api/outbound')
        .then(response => response.json())
        .then(data => this.scoringData = data)
        .catch(error => console.error('Error fetching outbound table data:', error));
    },
    generateNewScoringId() {
      if (this.scoringData.length > 0) {
        const maxScoringId = Math.max(...this.scoringData.map(call => call.scoringid));
        return maxScoringId + 1;
      } else {
        return 1; // Default ID if no calls exist
      }
    },

    submitNewCall() {
      const person = this.peopleList.find(person => person.personid === this.selectedPerson);
      if (person) {
        // change stores ?
        this.$store.commit('scoring/updateSelectedPerson', person);
      }
      if (this.selectedDealer && this.selectedPerson) {
        const newScoringId = this.generateNewScoringId();
        // changes stores ? 
        this.$store.commit('scoring/updateNewScoringId', newScoringId);
        this.$router.push({ name: 'ScoreSheetCoachAssistantOutbound', params: { id: newScoringId } });

        this.showRatingSection = true;
      } else {
        alert("Please select both a dealer and a person.");
      }
    },

    resetSelectedCall() {
      this.showRatingSection = !this.showRatingSection;
    }
  },

  watch: {
    selectedDealer(newVal) {
      const dealer = this.dealersList.find(dealer => dealer.dealerid === newVal);
      if (dealer) {
        this.$store.commit('scoring/updateSelectedDealer', dealer);
      }
    },
    selectedPerson(newVal) {
      const person = this.peopleList.find(person => person.personid === newVal);
      if (person) {
        this.$store.commit('scoring/updateSelectedPerson', person);
      }
    }
  },

  mounted() {
    this.fetchDealers();
    this.fetchScoringData();
  }
};
</script>

<style></style>
