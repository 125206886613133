<!-- <template>
  <auth-layout pageClass="register-page">
    
  </auth-layout>
</template>
<script>
  import { FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'

  export default {
    components: {
      FadeRenderTransition,
      AuthLayout
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style> -->

<template>
  <div class="container-fluid">
    <div class="row justify-content-start align-items-start">
      <div class="col-auto w-100">
        <div class="row d-flex justify-content-center align-items-start">
          <div class="col-md-6">
            <div class="header-text">
              <p>{{ $store.state.auth.userData.firstname + ' ' + $store.state.auth.userData.lastname }}</p>
              <p>{{ "Role:" + ' ' + $store.state.auth.userData.role }}</p>
              <p>{{ "Email:" + ' ' + $store.state.auth.userData.email }}</p>
              <hr>
            </div>
          </div>

          <div class="col-md-6 mr-auto">
            <form @submit.prevent="changePassword">
              <card class="card-plain">
                <p>Update your Password</p>
                <div>
                  <!-- <fg-input placeholder="Your First Name"></fg-input>
                  <fg-input placeholder="Your Last Name"></fg-input>
                  <fg-input placeholder="Company"></fg-input> -->
                  <fg-input placeholder="Current password" v-model="currentPassword" type="currentpassword"></fg-input>
                  <fg-input placeholder="Password" v-model="newPassword1" type="password"></fg-input>
                  <fg-input placeholder="Password confirmation" v-model="newPassword2" type="password"></fg-input>
                  <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                  </div>
                  <div v-if="successMessage" class="alert alert-success">
                    {{ successMessage }}
                  </div>
                </div>
                <div class="text-center">
                  <button @click="changePassword" type="button"
                    class="btn btn-fill btn-default btn-neutral btn-wd">Update Password</button>
                </div>
              </card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form } from 'element-ui'
//import { mapState } from "vuex";
import { FadeRenderTransition } from 'src/components/index'
import intro from '../../../components/intro.vue';
import ScoreSheetCoachAssistantInbound from '../../../components/ScoreSheetCoachAssistantInbound.vue';
export default {
  components: {
    //FadeRenderTransition,
    // intro,
    // ScoreSheetCoachAssistantInbound,
    // 'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // 'el-pagination': Pagination,
    // // 'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    // [DatePicker.name]: DatePicker,
    // [TimeSelect.name]: TimeSelect,
    // [Slider.name]: Slider,
    // [Tag.name]: Tag,
    // [Input.name]: Input,
    // [Button.name]: Button,
    // [Option.name]: Option,
    // [Select.name]: Select,
    // [Table.name]: Table,
    // [TableColumn.name]: TableColumn
  },
  data() {
    return {
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
      errorMessage: '',
      successMessage: ''
    };
  },
  computed: {
    //...mapState('session', ['total']),



  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    async changePassword() {
      const formData = new FormData();

      // Append form data
      formData.append('currentPassword', this.currentPassword);
      formData.append('newPassword1', this.newPassword1);
      formData.append('newPassword2', this.newPassword2);

      try {
        // Retrieve the token from localStorage (ensure 'currentUser' exists)
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;

        if (!token) {
          // Handle case when the token is missing
          this.errorMessage = "Authorization token is missing. Please log in.";
          return;
        }

        // Make the API request
        const response = await fetch('/api/change-password', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Ensure token is passed
          },
          body: formData, // Send the form data
        });

        const result = await response.json();

        if (result.success) {
          this.successMessage = result.message;
        } else {
          this.errorMessage = result.error || 'Failed to change the password. Please try again.';
        }
      } catch (error) {
        console.error('Error sending password change request:', error);
        this.errorMessage = 'There was an error processing your request.';
      }
    },
  },
  watch: {

  },
  created() {

  },
  beforeDestroy() {
    this.closeMenu()
  },
  mounted() {

  },
};
</script>

<style></style>
