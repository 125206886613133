/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.2
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate';
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'
import store from './store/store.js'
import 'process/browser';
import routes, { applyNavigationGuards } from './routes/routes.js'; // Import routes and the function

// router setup
// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(Vuelidate);


// Configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
});
// Apply navigation guards to the router
applyNavigationGuards(router);

/* eslint-disable no-new */
new Vue({
  store,
  el: '#app',
  render: h => h(App),
  router
})
